import React, { useCallback, useEffect, useState } from 'react';

import { TimeSlot, Schedule, ScheduleSelection } from '#types';

import useScheduling from '#hooks/useScheduling';

import { Dimension, settings } from '#materials';
import DatePicker from '#materials/DatePicker';
import DateTimePicker from '#materials/DateTimePicker';

import {
  timeScales,
  roundDateTime,
  shiftLocalDateTime,
} from '#utils/date';
import locale, { localize } from '#utils/locale';

const localeFormKeys = locale.keys.forms.orders;

interface TimeSlotPickerProps {
  label? : string;
  date? : Date | null;
  toDate? : Date | null;
  timeSlot? : TimeSlot | null;
  schedules? : Schedule | Schedule[];
  selectTime? : boolean;
  selectRange? : boolean;
  onChange? : (slots : ScheduleSelection[]) => void;
  onDateChange? : (date : Date | null) => void;
  onToDateChange? : (date : Date | null) => void;
  allowClear? : boolean;
  errors? : string[];
  toErrors? : string[];
  disabled? : boolean;
  width? : Dimension
}
interface TimeSlotPickerTimeProps extends TimeSlotPickerProps {
  toDate? : undefined;
  selectTime : true;
  selectRange? : false | undefined;
  toErrors? : undefined;
}
interface TimeSlotRangePickerProps extends TimeSlotPickerProps {
  selectTime? : false | undefined;
  selectRange : true;
}

function TimeSlotPicker(props : TimeSlotPickerProps) : JSX.Element;
function TimeSlotPicker(props : TimeSlotPickerTimeProps) : JSX.Element;
function TimeSlotPicker(props : TimeSlotRangePickerProps) : JSX.Element;
function TimeSlotPicker({
  label = '',
  date : fromDate,
  toDate,
  timeSlot = null,
  schedules,
  selectRange = false,
  selectTime = !selectRange,
  allowClear = false,
  onChange,
  onDateChange,
  onToDateChange,
  errors,
  toErrors,
  disabled = false,
  width = settings.dimensions.full,
} : TimeSlotPickerProps) {
  const {
    validateTimeSlotDay,
    validateTimeSlotDateTime,
    validateScheduleDay,
    validateScheduleTime,
    calculateScheduleMinStep,
    calculateTime,
    findSelections,
  } = useScheduling();

  const [validSchedules, setValidSchedules] = useState(schedules
    ? (schedules instanceof Array ? schedules : [schedules])
    : []);
  const [minStep, setMinStep] = useState(
    calculateScheduleMinStep(validSchedules),
  );

  const [lastResult, setLastResult] = useState<ScheduleSelection[]>([]);

  const update = useCallback((selections : ScheduleSelection[]) => {
    let updatedNeeded = false;
    if (selections.length !== lastResult.length) updatedNeeded = true;
    for (let i = 0; i < selections.length; i++) {
      const n = selections[i];
      const match = lastResult.find((m) => ((m.timeSlot.id === n.timeSlot.id)
        && (m.iteration === n.iteration)
        && (m.division === n.division)));
      if (match) continue;
      updatedNeeded = true;
      break;
    }

    if (!updatedNeeded) return;
    setLastResult(selections);
    onChange?.(selections);
  }, [lastResult, onChange]);

  const updateFromDate = useCallback((from : Date | null, to : Date | null) => {
    if (!onChange) return;

    if (!from && (!selectRange || !to)) {
      update([]);
      return;
    }

    if (!from) return;
    if (selectRange) {
      const targetDate = shiftLocalDateTime(to ?? from, 86400000);
      update(findSelections(null, from, targetDate));
      return;
    }

    if (selectTime) {
      const selections = findSelections(null, from, to ?? from);
      if (selections) {
        if (to) {
          const times = selections
            .filter((s) => validSchedules.some((v) => Object.keys(v.timeSlots)
              .includes(`${s.timeSlot.id}`)))
            .map((s) => calculateTime(
              s.timeSlot,
              s.iteration,
              s.division,
            ))
            .sort();
          const time = times[0] ?? null;
          if (time) {
            onDateChange?.(time);
            updateFromDate(time, null);
            return;
          }
        }
        update(selections);
        return;
      }
    }

    const start = roundDateTime(from, timeScales.day);
    const end = shiftLocalDateTime(start, 86400000);
    onChange(findSelections(null, start, end))
  }, [
    selectRange,
    selectTime,
    onChange,
    onDateChange,
    validSchedules,
    update,
    calculateTime,
    findSelections,
  ]);

  const updateToDate = useCallback((from : Date | null, to : Date | null) => {
    if (!onChange) return;
    if (!from) {
      update([]);
      return;
    }

    const targetDate = shiftLocalDateTime(
      selectRange ? to ?? from : from,
      86400000,
    );
    update(findSelections(null, from, targetDate));
  }, [selectRange, onChange, update, findSelections]);

  const handleFromDateChange = useCallback((newDate : Date | null) => {
    if (onDateChange) onDateChange(newDate);
    const day = newDate ? roundDateTime(newDate, timeScales.day) : null;
    updateFromDate(
      day,
      day ? shiftLocalDateTime(day, 86400000) : null,
    );
  }, [onDateChange, updateFromDate]);

  const handleFromTimeChange = useCallback((newDate : Date | null) => {
    if (onDateChange) onDateChange(newDate);
    updateFromDate(newDate, null);
  }, [onDateChange, updateFromDate]);

  const handleToDateChange = useCallback((newDate : Date | null) => {
    if (onToDateChange) onToDateChange(newDate);
    updateToDate(fromDate ?? null, newDate);
  }, [fromDate, onToDateChange, updateToDate]);

  const validateDate = useCallback(
    (date : Date) => validateScheduleDay(validSchedules, date)
      && (!timeSlot || validateTimeSlotDay(timeSlot, date)),
    [validSchedules, timeSlot, validateScheduleDay, validateTimeSlotDay],
  );

  const validateToDate = useCallback(
    (date : Date) => validateDate(date) && (!fromDate || date >= fromDate),
    [validateDate, fromDate],
  );

  const validateTime = useCallback(
    (time : Date) => validateScheduleTime(validSchedules, time)
      && (!timeSlot || validateTimeSlotDateTime(timeSlot, time)),
    [validSchedules, timeSlot, validateScheduleTime, validateTimeSlotDateTime],
  );

  useEffect(() => {
    const s = schedules
      ? (schedules instanceof Array ? schedules : [schedules])
      : [];
    if (
      s.length === validSchedules.length
        && s.every((v, i) => v === validSchedules[i])
    ) return;
    setValidSchedules(s);
    setMinStep(calculateScheduleMinStep(s));
  }, [schedules, validSchedules, calculateScheduleMinStep]);

  return (
    <>
      { selectTime
        ? (<DateTimePicker
          dateLabel={`${label} ${localize(localeFormKeys.labels.date)}`.trim()}
          timeLabel={`${label} ${localize(localeFormKeys.labels.time)}`.trim()}
          value={fromDate ?? null}
          setValue={handleFromDateChange}
          minuteStep={minStep / 60000}
          validateDate={validateDate}
          validateTime={validateTime}
          allowClear={allowClear}
          errors={errors}
          disabled={disabled}
          width={width}
        />) : (<DatePicker
          label={selectRange
            ? `${label} ${localize(localeFormKeys.labels.fromDate)}`.trim()
            : `${label} ${localize(localeFormKeys.labels.date)}`.trim()}
          value={fromDate ?? null}
          setValue={handleFromTimeChange}
          validate={validateDate}
          allowClear={allowClear}
          round
          errors={errors}
          disabled={disabled}
          width={width}
        />)
      }
      { selectRange && (
        <DatePicker
          label={`${label} ${localize(localeFormKeys.labels.toDate)}`.trim()}
          value={toDate ?? null}
          setValue={handleToDateChange}
          validate={validateToDate}
          allowClear={allowClear}
          round
          errors={toErrors}
          disabled={disabled}
          width={width}
        />
      )}
    </>
  );
}

export default TimeSlotPicker;
