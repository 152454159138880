import React, { useCallback, useState } from 'react';

import { Area } from '#types';

import { FormProvider } from '#context/FormContext';

import useNotifications from '#hooks/useNotifications';
import { useFormContext } from '#hooks/useForm';
import useAddresses from '#hooks/useAddresses';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';

import AreaForm from '#components/areas/AreaForm';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.areas.areaDetails;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.areas;

interface AreaDetailsProps {
  area : Area;
  enableDelete? : boolean;
  beforeDelete? : () => Promise<boolean>;
  onUpdate? : (area : Area) => Promise<void>;
}

function AreaDetailsControl({
  area : init,
  enableDelete = true,
  beforeDelete,
  onUpdate
} : AreaDetailsProps) {
  const {
    state : area,
    reset,
    editing,
    setEditing,
    valid,
  } = useFormContext<Area>();

  const { createNotification } = useNotifications();
  const { updateArea, deleteArea } = useAddresses();

  const [deleting, setDeleting] = useState(false);

  const handleEdit = useCallback(() => {
    setEditing(true);
  }, [setEditing]);

  const handleSave = useCallback(async () => {
    if (!area || !valid) return;

    const firstVertex = area.vertices[0];
    const lastVertex = area.vertices[area.vertices.length - 1];
    if (
      (firstVertex[0] !== lastVertex[0])
        || (firstVertex[1] !== lastVertex[1])
    ) {
      area.vertices.push([...firstVertex]);
    }

    const updatedArea = await updateArea(area);
    if (updatedArea) {
      if (onUpdate) await onUpdate(updatedArea);
      setEditing(false);
    }
  }, [area, valid, onUpdate, updateArea, setEditing]);

  const handleCancel = useCallback(() => {
    reset();
    setEditing(false);
  }, [reset, setEditing]);

  const handleInitDelete = useCallback(() => {
    setDeleting(true);
  }, [setDeleting]);

  const handleCancelDelete = useCallback(() => {
    setDeleting(false);
  }, [setDeleting]);

  const handleConfirmDelete = useCallback(async () => {
    if (!area) return

    const precheck = !beforeDelete || await beforeDelete();
    if (!precheck) return;

    const success = await deleteArea(area);
    if (success) {
      createNotification({
        key : 'delete-area-success',
        message : localize(localeNotificationKeys.remove.success),
        icon : (<Icon icon={settings.svgIcons.route} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'delete-area-error',
        message : localize(localeNotificationKeys.remove.error),
        icon : (<Icon icon={settings.svgIcons.route} />),
        colour : settings.colours.alert.alert,
      });
    }
    setDeleting(false);
  }, [area, beforeDelete, setDeleting, deleteArea, createNotification]);

  return (
    <>
      { deleting && (
        <Banner
          icon={<Icon icon={settings.svgIcons.delete} />}
          actions={(
            <>
              <Button
                onClick={handleConfirmDelete}
                colour={settings.colours.button.alert}
              >
                { localize(localeButtonKeys.remove) }
              </Button>
              <Button
                onClick={handleCancelDelete}
              >
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          )}
          colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.confirmRemove) }
        </Banner>
      ) }
      { (area && editing)
        ? <>
          <Button
            onClick={handleSave}
            disabled={!valid}
          >
            { localize(localeButtonKeys.save) }
          </Button>
          <Button
            onClick={handleCancel}
            colour={settings.colours.button.alert}
          >
            { localize(localeButtonKeys.cancel) }
          </Button>
        </>
        : <>
          <Button
            onClick={handleEdit}
            disabled={deleting}
          >
            { localize(localeButtonKeys.edit) }
          </Button>
          <Button
            onClick={handleInitDelete}
            colour={settings.colours.button.alert}
            disabled={!enableDelete || deleting}
          >
            { localize(localeButtonKeys.delete) }
          </Button>
        </>
      }
      <AreaForm area={area || init} />
    </>
  );
}

function AreaDetails({
  area,
  enableDelete,
  beforeDelete,
  onUpdate,
} : AreaDetailsProps) {
  return (
    <FormProvider init={area} editingInit={false}>
      <AreaDetailsControl
        area={area}
        enableDelete={enableDelete}
        beforeDelete={beforeDelete}
        onUpdate={onUpdate}
      />
    </FormProvider>
  );
}

export default AreaDetails;
