import React, { useEffect, useCallback, useState } from 'react';

import { Location } from '#types';

import useNotifications from '#hooks/useNotifications';
import useServices from '#hooks/useServices';
import useOrders from '#hooks/useOrders';

import { settings } from '#materials';
import Form from '#materials/Form';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Select from '#materials/Select';
import DateTimePicker from '#materials/DateTimePicker';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.holds.createHold;
const localeFormKeys = locale.keys.forms.holds;
const localeNotificationKeys = locale.keys.notifications.holds;

const DEFAULT_MINUTES = 15;

interface CreateHoldProps {
  disabled? : boolean;
}

function CreateHold({ disabled } : CreateHoldProps) {
  const { createNotification } = useNotifications();
  const { services, locations: locationsIndex } = useServices();
  const { createHold } = useOrders();

  const [locations, setLocations] = useState(listRecords(locationsIndex));
  const [location, setLocation] = useState<Location | null>(null);

  const [start, setStart] = useState<Date | null>(null);
  const [end, setEnd] = useState<Date | null>(null);

  const [errors, setErrors] = useState<string[]>([]);

  const pause = useCallback(async () => {
    if (!location || !!errors.length) return;

    const serviceIds = listRecords(services).filter(
      (service) => service.locationId === location.id
    ).map((service) => service.id).filter((service) => !!service) as number[];
    const success = await createHold({
      serviceIds,
      start: start ?? new Date(),
      end: end ?? new Date(
        (start ?? new Date()).getTime() + DEFAULT_MINUTES * 60000
      ),
    });
    if (success) {
      createNotification({
        key : 'create-hold-success',
        message : localize(localeNotificationKeys.create.success),
        icon : <Icon icon={settings.svgIcons.store} />,
        colour : settings.colours.alert.primary,
      });
      setLocation(null);
      setStart(null);
      setEnd(null);
    } else {
      createNotification({
        key : 'create-hold-failure',
        message : localize(localeNotificationKeys.create.error),
        icon : <Icon icon={settings.svgIcons.store} />,
        colour : settings.colours.alert.alert,
      });
    }
  }, [location, start, end, errors, services, createHold, createNotification]);

  useEffect(() => setLocations(listRecords(locationsIndex)), [locationsIndex]);

  useEffect(() => {
    if (start && end && start > end) {
      setErrors([localize(localeFormKeys.errors.endBeforeStart)]);
    } else {
      setErrors([]);
    }
  }, [start, end]);

  const minutes = end && start
    ? Math.floor((end.getTime() - start.getTime()) / 60000)
    : DEFAULT_MINUTES;

  return (
    <>
      <Button
        onClick={pause}
        disabled={disabled || !location || !!errors.length}
      >
        { (location && !errors.length)
          ? `${localize(localeContentKeys.pauseOrders)} (${minutes} min)`
          : localize(localeContentKeys.selectLocation)
        }
      </Button>
      <Form>
        <Select
          label={localize(localeFormKeys.labels.location)}
          selected={location}
          options={locations}
          onChange={setLocation}
          disabled={disabled}
          labelGenerator={(location) => location?.name ?? ''}
          keyGenerator={(location) => `${location?.id}`}
          width={settings.dimensions.third}
        />
        <DateTimePicker
          dateLabel={localize(localeFormKeys.labels.start)}
          timeLabel=""
          value={start}
          setValue={setStart}
          allowClear
          disabled={disabled || !location}
          width={settings.dimensions.third}
        />
        <DateTimePicker
          dateLabel={localize(localeFormKeys.labels.end)}
          timeLabel=""
          value={end}
          setValue={setEnd}
          allowClear
          disabled={disabled || !location}
          width={settings.dimensions.third}
          errors={errors}
        />
      </Form>
    </>
  );
}

export default CreateHold;
