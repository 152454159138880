import React from 'react';

import { ConfigProvider } from '#context/ConfigContext';

interface ProviderProps {
  children : React.ReactNode;
}

function Providers({ children } : ProviderProps) {

  return (
    <ConfigProvider>
      { children }
    </ConfigProvider>
  );
}

export default Providers;
