import React, { useCallback } from 'react';

import { TimeSlot } from '#types';

import useScheduling from '#hooks/useScheduling';

import Table from '#materials/Table';
import { TableCell, CellElement } from '#materials/TableCell';

import locale, { localize } from '#utils/locale';
import  formats, { formatDate, formatDateTime } from '#utils/date';

const localKeys = locale.keys.tables.timeSlots;

export const TABLE_KEYS = {
  id : 'id',
  name : 'name',
  start : 'start',
  nextWindow : 'nextWindow',
  action : 'actions',
}
export type TableKeys = typeof TABLE_KEYS[keyof typeof TABLE_KEYS];

interface TimeSlotTableProps {
  timeSlots : TimeSlot[];
  count : number;
  generateActions? : (timeSlot : TimeSlot) => CellElement;
  tableKeys? : TableKeys[];
}

function TimeSlotTable({
  timeSlots,
  count,
  generateActions = () => <TableCell />,
  tableKeys = Object.values(TABLE_KEYS),
} : TimeSlotTableProps) {
  const { findNextIteration, calculateDate } = useScheduling();

  const headGenerator = useCallback((key : string) => {
    switch (key) {
      case TABLE_KEYS.id:
        return <TableCell>{ localize(localKeys.headings.id) }</TableCell>;
      case TABLE_KEYS.name:
        return <TableCell>{ localize(localKeys.headings.name) }</TableCell>;
        case TABLE_KEYS.start:
        return <TableCell>{ localize(localKeys.headings.start) }</TableCell>;
      case TABLE_KEYS.nextWindow:
        return <TableCell>
          { localize(localKeys.headings.nextWindow) }
        </TableCell>;
      case TABLE_KEYS.action: return <TableCell />;
      default: return <TableCell />;
    }
  }, []);

  const rowGenerator = useCallback((key : string, index : number) => {
    const timeSlot = timeSlots[index];
    switch (key) {
      case TABLE_KEYS.id: return <TableCell>{ `# ${timeSlot.id}` }</TableCell>;
      case TABLE_KEYS.name: return <TableCell>{ timeSlot.name }</TableCell>;
      case TABLE_KEYS.start: return <TableCell>
        { formatDate(timeSlot.start, formats.descript) }</TableCell>;
      case TABLE_KEYS.nextWindow:
        const nextIteration = findNextIteration(timeSlot, new Date());
        const nextWindow = nextIteration
          ? calculateDate(timeSlot, nextIteration)
          : null;
        return <TableCell>
        { nextWindow
          ? formatDateTime(nextWindow, formats.descript)
          : ''
        }
      </TableCell>;
      case TABLE_KEYS.action:
        return generateActions(timeSlot);
      default: return <TableCell />;
    }
  }, [timeSlots, generateActions, findNextIteration, calculateDate]);

  return (
    <Table
      keys={tableKeys}
      length={count}
      headGenerator={headGenerator}
      rowGenerator={rowGenerator}
    />
  );
}

export default TimeSlotTable;
