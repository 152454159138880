import React, { useCallback, useEffect, useState } from 'react';

import { DraftOrder } from '#types';

import useProducts from '#hooks/useProducts';
import useTags from '#hooks/useTags';
import useOrders from '#hooks/useOrders';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Table from '#materials/Table';
import { CellElement, TableCell } from '#materials/TableCell';

import { listRecords } from '#utils/data';
import { formats, formatDate, formatTime } from '#utils/date';
import locale, { localize } from '#utils/locale';

const localeTableKeys = locale.keys.tables.orders;

export const TABLE_KEYS = {
  info : 'info',
  id : 'id',
  service : 'service',
  timeSlot : 'timeSlot',
  date : 'date',
  time : 'time',
  customer : 'customer',
  status : 'status',
  actions : 'actions',
} as const;
export type TableKeys = typeof TABLE_KEYS[keyof typeof TABLE_KEYS];
export const defatultTableKeys = Object.values(TABLE_KEYS).filter(
  k => k !== TABLE_KEYS.timeSlot,
);

interface OrderTableProps {
  orders : DraftOrder[];
  tableKeys? : TableKeys[];
  pageCount? : number;
  generateActions? : (order : DraftOrder) => CellElement;
}

function OrderTable({
  orders,
  tableKeys = defatultTableKeys,
  pageCount = 20,
  generateActions,
} : OrderTableProps) {
  const { products } = useProducts();
  const { isProductAvailable } = useTags();
  const { findServices } = useOrders();
  const [head, setHead] = useState<React.ReactNode>(<></>);
  const [rows, setRows] = useState<React.ReactNode[]>([]);

  const generateHead = useCallback(() => {
    setHead(
      <>
        { tableKeys.map((key) => {
          const mapKey = `product-table-head=${key}`
          switch(key) {
            case TABLE_KEYS.id:
              return (<TableCell
                key={mapKey}
                width={settings.dimensions.xsmall}
              >
                { localize(localeTableKeys.headings.id) }
              </TableCell>);
            case TABLE_KEYS.service:
              return (<TableCell key={mapKey}>
                { localize(localeTableKeys.headings.service) }
              </TableCell>);
            case TABLE_KEYS.timeSlot:
              return (<TableCell key={mapKey}>
                { localize(localeTableKeys.headings.timeSlot) }
              </TableCell>);
            case TABLE_KEYS.date:
              return (<TableCell key={mapKey}>
                { localize(localeTableKeys.headings.date) }
              </TableCell>);
            case TABLE_KEYS.time:
              return (<TableCell key={mapKey}>
                { localize(localeTableKeys.headings.time) }
              </TableCell>);
            case TABLE_KEYS.status:
              return (<TableCell key={mapKey} width={settings.dimensions.small}>
                { localize(localeTableKeys.headings.status) }
              </TableCell>);
            case TABLE_KEYS.customer:
              return (<TableCell key={mapKey}>
                { localize(localeTableKeys.headings.customer) }
              </TableCell>);
            default: return <TableCell key={mapKey} />;
          }
        }) }
      </>
    );
  }, [tableKeys]);

  const generateRows = useCallback(() => {
    setRows(orders.map((order) => {
      const rk = `${order.customer?.id}-${order.serviceChannel?.id}` +
        `-${order.timeSlot?.id}-${order.timeSlotIteration}` +
        `-${order.timeSlotDivision}`;

      const channel = order.serviceChannel;
      const invalidProducts = channel
        && listRecords(order.lineItems).some((item) => {
          const product = products?.[item.productId];
          if (!product) return false;
          return !isProductAvailable(product, channel);
        })
      return (
        <React.Fragment key={rk}>
          { tableKeys.map((key) => {
            const service = findServices({ ...order, service : null })[0];
            switch(key) {
              case TABLE_KEYS.id:
                return (<TableCell key={key} width={settings.dimensions.xsmall}>
                  { order?.order?.id ? `# ${order.order?.id}` : '' }
                </TableCell>);
              case TABLE_KEYS.info:
                return (<TableCell key={key} width={settings.dimensions.xsmall}>
                  { (!service || !!order.errors.length || invalidProducts) && (
                    <Icon
                      icon={settings.svgIcons.info}
                      colour={settings.colours.button.alert}
                    />
                  ) }
                </TableCell>);
              case TABLE_KEYS.service:
                return (<TableCell key={key}>
                  { service
                    ? service.name
                    : localize(localeTableKeys.defaults.service)
                  }
                </TableCell>);
              case TABLE_KEYS.timeSlot:
                return (<TableCell key={key}>
                  { order.timeSlot?.name }
                </TableCell>);
              case TABLE_KEYS.date:
                return (<TableCell key={key} width={settings.dimensions.small}>
                  { order.time ? formatDate(order.time, formats.short) : '' }
                </TableCell>);
              case TABLE_KEYS.time:
                return (<TableCell key={key} width={settings.dimensions.small}>
                  { (order.time) ? formatTime(order.time, formats.short) : '' }
                </TableCell>);
              case TABLE_KEYS.status:
                return (<TableCell key={key}  width={settings.dimensions.small}>
                  { (order.status === 'pending' && order.paid
                    ? localize(localeTableKeys.defaults.status.paid)
                    : order.status)?.toLocaleUpperCase() }
                </TableCell>);
              case TABLE_KEYS.customer:
                return (<TableCell key={key}>
                  { order.customer
                    ? order.customer?.defaultName
                    : localize(localeTableKeys.defaults.guest)
                      + ` (${order.guestCode})`
                  }
                </TableCell>);
              case TABLE_KEYS.actions:
                return (
                  <React.Fragment key={key}>
                    { generateActions
                      ? generateActions(order)
                      : <TableCell key={key} />
                    }
                  </React.Fragment>
                );
              default: return (<TableCell key={key} />);
            }
          }) }
        </React.Fragment>
      )
    }));
  }, [
    orders,
    tableKeys,
    generateActions,
    products,
    isProductAvailable,
    findServices,
  ]);

  useEffect(() => { generateHead() }, [generateHead]);
  useEffect(() => { generateRows() }, [generateRows]);

  return (
    <Table
      head={head}
      rows={rows}
      pageCount={pageCount}
    />
  );
}

export default OrderTable;
