import React from 'react';

import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box/Box';
import Paper from '@mui/material/Paper';

interface CardProps {
  children : React.ReactNode;
}

function Card({ children } : CardProps) {
  const isPrint = useMediaQuery('print');

  return (
    <Paper
      elevation={isPrint ? 0 : 1}
      sx={{
        my : 2,
        p : 2,
        pageBreakInside : 'avoid',
      }}
    >
      <Box
        sx={{
          overflow : 'hidden',
        }}
      >
        { children }
      </Box>
    </Paper>
  );
}

export default Card;
