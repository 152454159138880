import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Fulfilment, LineItem, Selection, DraftCustomOrder } from '#types';

import useOptions from '#hooks/useOptions';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Table from '#materials/Table';
import { TableActionCell, Action, CellElement } from '#materials/TableCell';

import SelectionRow, {
  SELECTION_TABLE_KEYS,
  SelectionTableKey,
  defaultSelectionTableKeys,
} from '#components/selections/SelectionRow';
import { OrderFormMode } from '#components/orders/OrderForm';

import locale, { localize } from '#utils/locale';

export type { SelectionTableKey as TableKey };
export { SELECTION_TABLE_KEYS, defaultSelectionTableKeys };

const localButtonKeys = locale.keys.buttons;

interface SelectionTableProps {
  lineItem : LineItem;
  selections : Selection[];
  setSelection? : (selection : Selection) => void;
  order? : DraftCustomOrder;
  mode? : OrderFormMode;
  disabled? : boolean;
  updateFulfilment? : (fulfilment : Fulfilment) => void | Promise<void>;
  deleteFulfilment? : (fulfilment : Fulfilment) => void | Promise<void>;
  generateActions? : (selection : Selection | null) => CellElement;
  extraRow? : React.ReactNode;
  tableKeys? : SelectionTableKey[];
}

function SelectionTable({
  lineItem,
  selections,
  order,
  mode,
  extraRow,
  disabled = false,
  tableKeys = defaultSelectionTableKeys,
  setSelection,
  updateFulfilment,
  deleteFulfilment,
  generateActions,
} : SelectionTableProps) {
  const { resolveSelections } = useOptions();

  const resolvedSelections = useMemo(() => {
    return (order && mode === 'fulfilment')
      ? resolveSelections(lineItem, order)
      : null
  }, [lineItem, order, mode, resolveSelections]);

  const generateRows = useCallback(() => {
    const generateOrphanedActions = (
      selection : Selection | null,
      fulfilment? : Fulfilment | null,
    ) => {
      return (!disabled && fulfilment?.id
        ? (
          <TableActionCell>
            <Action
              onClick={() => deleteFulfilment?.(fulfilment)}
              label={localize(localButtonKeys.delete)}
              colour={settings.colours.button.alert}
            >
              <Icon icon={settings.svgIcons.clear} />
            </Action>
          </TableActionCell>
        ) : <></>
      )
    }
    const rows = resolvedSelections
      ? resolvedSelections.map((resolution, i) => (
        <SelectionRow
          key={(resolution.selection?.id ?? '#')
            + `-${resolution.fulfilment?.id ?? '#'}-${i}`}
          lineItem={lineItem}
          selection={resolution.selection}
          fulfilment={resolution.fulfilment}
          assembly={resolution.assembly}
          order={order}
          tableKeys={tableKeys}
          disabled={disabled}
          mode={mode}
          setSelection={setSelection}
          onUpdateFulfilment={updateFulfilment}
          generateActions={resolution.selection
            ? generateActions
            : generateOrphanedActions}
        />
      ))
      : selections.map((selection) => (
        <SelectionRow
          key={`${selection.id}`}
          lineItem={lineItem}
          selection={selection}
          order={order}
          tableKeys={tableKeys}
          disabled={disabled}
          mode={mode}
          setSelection={setSelection}
          onUpdateFulfilment={updateFulfilment}
          generateActions={generateActions}
        />
      ));
    if (extraRow) {
      rows.push(<React.Fragment key={'extra'}>{ extraRow }</React.Fragment>);
    }
    return rows;
  }, [
    lineItem,
    selections,
    order,
    tableKeys,
    extraRow,
    disabled,
    mode,
    resolvedSelections,
    setSelection,
    updateFulfilment,
    deleteFulfilment,
    generateActions,
  ]);

  const [rows, setRows] = useState<React.ReactNode[]>(generateRows());

  useEffect(() => { setRows(generateRows()) }, [generateRows]);

  return (
    <Table
      head={null}
      rows={rows}
    />
  );
}

export default SelectionTable;
