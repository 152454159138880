import React, { useCallback, useState } from 'react';

import { Customer, Integration, ExternalCustomer } from '#types';

import useNotifications from '#hooks/useNotifications';
import useCustomers from '#hooks/useCustomers';
import useCards from '#hooks/useCards';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import { TableActionCell, Action } from '#materials/TableCell';

import Section from '#components/dashboard/Section';
import ExternalCustomerSearch from
  '#components/customers/ExternalCustomerSearch';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.customers.importCustomer;
const localeButtonKeys = locale.keys.buttons;
const localeTableKeys = locale.keys.tables.customerIntegrations;
const localeNotificationKeys = locale.keys.notifications;

interface ImportCustomerProps {
  onImport? : (customer : Customer) => void;
  onCancel? : () => void;
}

function ImportCustomer({
  onImport,
  onCancel,
} : ImportCustomerProps) {
  const { createNotification } = useNotifications();
  const { importCustomer } = useCustomers();
  const { pullCreditCards } = useCards();

  const [integration, setIntegrations] = useState<Integration | null>(null);

  const handleImport = useCallback(
    async (externalCustomer : ExternalCustomer) => {
      if (!integration) return;

      const newCustomer = await importCustomer(integration, externalCustomer);
      const newCards = newCustomer
        ? await pullCreditCards({ customer : newCustomer })
        : null;

      if (newCustomer && newCards) {
        createNotification({
          key : 'import-customer-success',
          message : localize(localeNotificationKeys.customers.imported.success),
          colour : settings.colours.alert.primary,
          icon : <Icon icon={settings.svgIcons.person} />,
        })
        if (onImport) onImport(newCustomer);
      } else {
        createNotification({
          key : 'import-customer-error',
          message : localize(localeNotificationKeys.customers.imported.error),
          colour : settings.colours.alert.alert,
          icon : <Icon icon={settings.svgIcons.person} />,
        })
      }
    },
    [
      onImport,
      integration,
      importCustomer,
      pullCreditCards,
      createNotification,
    ],
  );

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);

  const generateActions = useCallback((customer : ExternalCustomer) => {
    return (<TableActionCell>
      <Action
        label={`${localize(localeTableKeys.actions.import)} (${customer.name})`}
        onClick={() => handleImport(customer)}
      >
        <Icon icon={settings.svgIcons.download} />
      </Action>
    </TableActionCell>);
  }, [handleImport]);

  return (
    <Section
      title={localize(localeContentKeys.title)}
      text={localize(localeContentKeys.body)}
    >
      <ExternalCustomerSearch
        integration={integration}
        setIntegration={setIntegrations}
        generateActions={generateActions}
      />
      <Button
        onClick={handleCancel}
        colour={settings.colours.alert.alert}
      >
        {localize(localeButtonKeys.cancel)}
      </Button>
    </Section>
  );
}

export default ImportCustomer;
