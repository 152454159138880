import React, { createContext } from 'react';

import { Address, Area } from '#mrktbox/clerk/types';

import { useResponse } from '#mrktbox/clerk/hooks/api/useAPI';
import useData, {
  DataIndex,
  useLoad,
} from '#mrktbox/clerk/hooks/useData';
import useCache, {
  checkCachedBulk,
  getCachedBulk,
} from '#mrktbox/clerk/hooks/useDataCache';
import useAddressesAPI from '#mrktbox/clerk/hooks/api/useAddressesAPI';

export type AddressIndex = DataIndex<Address>;
export type AreaIndex = DataIndex<Area>;

const MAX_AGE = 1000 * 60 * 60;

const AreaContext = createContext({
  addresses : null as DataIndex<Address> | null,
  areas : null as DataIndex<Area> | null,
  loaded : false as boolean,
  load : () => {},
  createAddress : async (address : Address) => null as Address | null,
  refreshAddress : async (id : number) => null as Address | null,
  retrieveAddressesBulk : async (ids : number[]) => null as AddressIndex | null,
  retrieveAddress : async (id : number) => null as Address | null,
  deleteAddress : async (address : Address) => null as Address | null,
  createArea : async (area : Area) => null as Area | null,
  refreshAreas : async () => null as AreaIndex | null,
  refreshArea : async (id : number) => null as Area | null,
  retrieveAreas : async () => null as AreaIndex | null,
  retrieveArea : async (id : number) => null as Area | null,
  updateArea : async (area : Area) => null as Area | null,
  deleteArea : async (area : Area) => null as Area | null,
  searchAddresses : async (query : string) => null as {
    [key : string] : Address;
  } | null,
});

interface AreaProviderProps {
  children : React.ReactNode;
}

export function AreaProvider({ children } : AreaProviderProps) {
  const {
    createAddress,
    retrieveAddressesBulk,
    retrieveAddress,
    deleteAddress,
    searchAddresses,
    createArea,
    retrieveAreas,
    retrieveArea,
    updateArea,
    deleteArea,
  } = useAddressesAPI();

  const {
    data : addresses,
    dispatch : dispatchAddresses,
    lastUpdated : lastUpdatedAddresses,
  } = useData<Address>({ storageKey : 'addresses' });

  const addressStale = (lastUpdatedAddresses !== undefined) &&
    (new Date().getTime() - lastUpdatedAddresses.getTime()) > MAX_AGE;

  const newAddress = useCache({
    process : createAddress,
    dispatch : dispatchAddresses,
  });
  const refreshAddress = useCache({
    process : retrieveAddress,
    dispatch : dispatchAddresses,
    isLoader : true,
  });
  const getAddressBulk = useCache({
    process : retrieveAddressesBulk,
    dispatch : dispatchAddresses,
    validator : checkCachedBulk,
    callback : getCachedBulk,
    data : addresses,
    isSplitLoader : true,
  });
  const getAddress = useCache({
    process : retrieveAddress,
    dispatch : dispatchAddresses,
    data : addresses,
    stale : addressStale,
    isLoader : true,
    dropNull : true,
  });
  const removeAddress = useCache({
    process : deleteAddress,
    dispatch : dispatchAddresses,
  });

  const {
    data : areas,
    dispatch : dispatchAreas,
    lastUpdated,
  } = useData<Area>({ storageKey : 'areas' });

  const areaStale = (lastUpdated !== undefined) &&
    (new Date().getTime() - lastUpdated.getTime()) > MAX_AGE;

  const newArea = useCache({
    process : createArea,
    dispatch : dispatchAreas,
  });
  const refreshAreas = useCache({
    process : retrieveAreas,
    dispatch : dispatchAreas,
    refresh : true,
    isLoader : true,
  });
  const refreshArea = useCache({
    process : retrieveArea,
    dispatch : dispatchAreas,
    isLoader : true,
    dropNull : true,
  });
  const getAreas = useCache({
    process : retrieveAreas,
    dispatch : dispatchAreas,
    data : areas,
    stale : areaStale,
    refresh : true,
    isLoader : true,
  });
  const getArea = useCache({
    process : retrieveArea,
    dispatch : dispatchAreas,
    data : areas,
    stale : areaStale,
    isLoader : true,
    dropNull : true,
  });
  const amendArea = useCache({
    process : updateArea,
    dispatch : dispatchAreas,
  });
  const removeArea = useCache({
    process : deleteArea,
    dispatch : dispatchAreas,
  });

  const queryAddresses = useResponse(searchAddresses);

  const { load, loaded } = useLoad({ data : areas, loader : refreshAreas });

  const context = {
    addresses,
    areas,
    loaded,
    load,
    createAddress : newAddress,
    refreshAddress,
    retrieveAddressesBulk : getAddressBulk,
    retrieveAddress : getAddress,
    deleteAddress : removeAddress,
    searchAddresses : queryAddresses,
    createArea : newArea,
    refreshAreas,
    refreshArea,
    retrieveAreas : getAreas,
    retrieveArea : getArea,
    updateArea : amendArea,
    deleteArea : removeArea,
  }

  return (
    <AreaContext.Provider value={context}>
      { children }
    </AreaContext.Provider>
  );
}

export default AreaContext;
