import { useContext } from 'react';

import AreaContext from '#mrktbox/clerk/context/AreaContext';

function useAddresses() {
  const context = useContext(AreaContext);

  return {
    ...context,
    loadAreas : context.load,
    areasLoaded : context.loaded,
  };
}

export default useAddresses;
