import React from 'react';

import useConfig from '#hooks/useConfig';

import Text from '#materials/Text';
import Link from '#materials/Link';
import { settings } from '#materials';

import locale from '#utils/locale';

function Copyright () {
  const { config } = useConfig();

  return (
    <Text
      variant={settings.variants.typography.body2}
      colour={settings.colours.text.primary}
      align={settings.alignments.center}
    >
      { locale.localize(locale.keys.content.copywright) }
      <Link href={config.brand.domain} newTab>
        { config.brand.name }
      </Link>
      { ' ' + new Date().getFullYear() }
    </Text>
  );
}

export default Copyright;
