import localeData from '../locale.json'

export interface LocaleText {
  [key : string] : string;
}

function getLocale() {
  return process.env.REACT_APP_LOCALE
    ? process.env.REACT_APP_LOCALE.replace('-', '_')
    : ''
}

export function localize(localeString : LocaleText) {
  const locale = getLocale()
  return locale in localeString
    ? localeString[locale]
    : Object.values(localeString)[0]
}

export const keys = localeData;

const locale = {
  localize,
  keys,
}

export default locale
