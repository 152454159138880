
export const actionTypes = {
  set : 'set',
  update : 'update',
  add : 'add',
  remove : 'remove',
  clear : 'clear',
} as const;
type ActionTypeKeys = keyof typeof actionTypes;
export type ActionType = typeof actionTypes[ActionTypeKeys];

export interface DataAction<RT> {
  data : DataIndex<RT>;
  type? : ActionType;
  timestamp? : Date | null;
}

export interface DataStore<RT> {
  storageKey : string;
  data : DataIndex<RT>;
  timestamp? : Date;
}

export type DataReducer<RT> = React.Reducer<
  DataStore<RT> | null,
  DataAction<RT>
>;
export type DataDispatch<RT> = React.Dispatch<DataAction<RT>>;

export interface DataIndex<RT> extends Record<string, RT | null> {}
