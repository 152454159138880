import React from 'react';
import MuiLink from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';

import { Colour } from './types';

interface LinkProps {
  href? : string;
  colour? : Colour;
  newTab? : boolean;
  children? : React.ReactNode;
}

function Link({ href, colour, newTab, children } : LinkProps) {
  const isPrint = useMediaQuery('print');

  return (
    <MuiLink
      color={colour ? colour : 'inherit'}
      href={href}
      target={newTab ? '_blank' : undefined}
      sx={{
        textDecoration : isPrint ? 'none' : undefined,
      }}
    >
      { children }
    </MuiLink>
  );
}

export default Link;
