import React, { forwardRef, useCallback } from 'react';

import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import MuiToggleButton from '@mui/material/ToggleButton';

interface ToggleButtonProps {
  value? : string;
  selected? : boolean;
  first? : boolean;
  last? : boolean;
  onSelect? : (selected : string) => void;
  children? : React.ReactNode;
}

const ToggleButton = forwardRef(({
  value,
  first,
  last,
  selected,
  onSelect,
  children,
} : ToggleButtonProps, ref : React.Ref<HTMLButtonElement>) => {
  const handleChange = useCallback(
    (event : React.MouseEvent<HTMLElement>, _ : boolean) => {
      onSelect?.(value ?? 'default');
    },
    [value, onSelect],
  );

  return (
    <MuiToggleButton
      ref={ref}
      value={value ?? 'default'}
      aria-label={value ?? 'default'}
      color='primary'
      selected={selected}
      onClick={handleChange}
      sx={{
        borderRadius : '0',
        ...(first && { borderRadius : '4px 0 0 4px' }),
        ...(last && { borderRadius : '0 4px 4px 0' }),
      }}
    >
      { children }
    </MuiToggleButton>
  );
});

interface ToggleButtonGroupProps {
  disabled? : boolean;
  children : React.ReactNode;
}

export function ToggleButtonGroup({
  disabled,
  children,
} : ToggleButtonGroupProps) {
  return (
    <MuiToggleButtonGroup
      disabled={disabled}
      sx={{
        height : '36.5px',
        m : [1],
      }}
    >
      { children }
    </MuiToggleButtonGroup>
  );
}

export default ToggleButton;
