import React, { useEffect, useState } from 'react';

import { SidebarProvider } from '#context/SidebarContext';

import useConfig from '#hooks/useConfig';
import useNavigation, { Outlet } from '#hooks/useNavigation';
import useAuth from '#hooks/useAuth';
import {
  READ_PERMISSION as integrationPermissions,
} from '#hooks/useIntegrations';

import Icon from '#materials/Icon';

import Toaster from '#components/dashboard/Toaster';
import AppView from '#components/dashboard/AppView';
import Workspace from '#components/dashboard/Workspace';
import NavigationItem, {
  NavigationItemGroup,
} from '#components/dashboard/NavigationItem';

import AppBar from '#components/dashboard/AppBar';
import { settings } from '#materials';
import locale, { localize } from '#utils/locale';

const localNavigationKeys = locale.keys.navigation;

function Dashboard() {
  const { config } = useConfig();
  const { isRouteActive } = useNavigation();
  const { checkAuth } = useAuth();

  const [readIntegrations, setReadIntegrations] = useState(
    checkAuth({ redirect : null, permissions : integrationPermissions })
  );

  useEffect(() => { checkAuth() }, [checkAuth]);
  useEffect(() => {
    setReadIntegrations(checkAuth({
      redirect : null,
      permissions : integrationPermissions }
    ));
  }, [checkAuth]);

  return (
    <AppView>
      <SidebarProvider>
        <AppBar
          title={config.brand.name}
          navItems={
            <>
              <NavigationItemGroup
                name={localize(localNavigationKeys.orders)}
                href="/orders/"
                selected={isRouteActive('/orders/')}
                icon={<Icon icon={settings.svgIcons.receipt} />}
              >
                <NavigationItem
                  href="/orders/report/"
                  selected={isRouteActive('/orders/')}
                  icon={<Icon icon={settings.svgIcons.assignment} />}
                >
                  { localize(localNavigationKeys.ordersReport) }
                </NavigationItem>
              </NavigationItemGroup>
              <NavigationItem
                href="/customers/"
                selected={
                  isRouteActive('/customers/') ||
                  isRouteActive('/addresses/') ||
                  isRouteActive('/customers/integration/')
                }
                icon={<Icon icon={settings.svgIcons.person} />}
              >
                { localize(localNavigationKeys.customers) }
              </NavigationItem>
              <NavigationItemGroup
                name={localize(localNavigationKeys.products)}
                href="/products/"
                selected={
                  isRouteActive('/products/') ||
                  isRouteActive('/tags/') ||
                  isRouteActive('/categories/') ||
                  isRouteActive('/suppliers/') ||
                  isRouteActive('/taxes/') ||
                  isRouteActive('/adjustments/')
                }
                icon={<Icon icon={settings.svgIcons.shoppingBag} />}
              >
                <NavigationItem
                  href="/tags/"
                  icon={<Icon icon={settings.svgIcons.localOffer} />}
                >
                  { localize(localNavigationKeys.tags) }
                </NavigationItem>
                <NavigationItem
                  href="/categories/"
                  icon={<Icon icon={settings.svgIcons.category} />}
                >
                  { localize(localNavigationKeys.categories) }
                </NavigationItem>
                <NavigationItem
                  href="/suppliers/"
                  icon={<Icon icon={settings.svgIcons.agriculture} />}
                >
                  { localize(localNavigationKeys.suppliers) }
                </NavigationItem>
                <NavigationItem
                  href="/taxes/"
                  icon={<Icon icon={settings.svgIcons.percent} />}
                >
                  { localize(localNavigationKeys.taxes) }
                </NavigationItem>
                <NavigationItem
                  href='/adjustments/'
                  icon={<Icon icon={settings.svgIcons.loyalty} />}
                >
                  Adjustments
                </NavigationItem>
              </NavigationItemGroup>
              <NavigationItemGroup
                name={localize(localNavigationKeys.services)}
                href="/services/"
                selected={
                  isRouteActive('/services/') ||
                  isRouteActive('/service-channels/') ||
                  isRouteActive('/locations/') ||
                  isRouteActive('/scheduling/') ||
                  isRouteActive('/time-slots/') ||
                  isRouteActive('/routes/') ||
                  isRouteActive('/revenue-centres/')
                }
                icon={<Icon icon={settings.svgIcons.localShipping} />}
              >
                <NavigationItem
                  href="/service-channels/"
                  icon={<Icon icon={settings.svgIcons.callSplit} />}
                >
                  { localize(localNavigationKeys.serviceChannels) }
                </NavigationItem>
                <NavigationItem
                  href="/locations/"
                  icon={<Icon icon={settings.svgIcons.store} />}
                >
                  { localize(localNavigationKeys.locations) }
                </NavigationItem>
                <NavigationItem
                  href="/scheduling/"
                  icon={<Icon icon={settings.svgIcons.dateRange}></Icon>}
                >
                  { localize(localNavigationKeys.schedules) }
                </NavigationItem>
                <NavigationItem
                  href="/time-slots/"
                  icon={<Icon icon={settings.svgIcons.accessTime} />}
                >
                  { localize(localNavigationKeys.timeSlots) }
                </NavigationItem>
                <NavigationItem
                  href="/routes/"
                  icon={<Icon icon={settings.svgIcons.route} />}
                >
                  { localize(localNavigationKeys.routes) }
                </NavigationItem>
                <NavigationItem
                  href="/revenue-centres/"
                  icon={<Icon icon={settings.svgIcons.pointOfSale} />}
                >
                  { localize(localNavigationKeys.revenueCentres) }
                </NavigationItem>
              </NavigationItemGroup>
              { readIntegrations && (
                <NavigationItemGroup
                  name={localize(localNavigationKeys.integrations)}
                  href="/integrations/"
                  selected={
                    isRouteActive('/integrations/') ||
                    isRouteActive('/channels/')
                  }
                  icon={<Icon icon={settings.svgIcons.integrationInstructions} />}
                >
                  <NavigationItem
                    href="/channels/"
                    icon={
                      <Icon icon={settings.svgIcons.settingsInputComponent} />
                    }
                  >
                    { localize(localNavigationKeys.channels) }
                  </NavigationItem>
                </NavigationItemGroup>
              ) }
              <NavigationItem
                href="/settings/"
                selected={isRouteActive('/settings/')}
                icon={<Icon icon={settings.svgIcons.settings} />}
              >
                { localize(localNavigationKeys.settings) }
              </NavigationItem>
            </>
          }
        >
          <> </>
        </AppBar>
      </SidebarProvider>
      <Workspace>
        <Outlet />
      </Workspace>
      <Toaster />
    </AppView>
  );
}

export default Dashboard;
