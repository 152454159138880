import React, { useCallback, useEffect, useState } from 'react';

import {
  Service,
  LineItem,
  Selection,
  DraftOrder,
  ProjectedOrder,
 } from '#types';

import  { FormProvider } from '#context/FormContext';

import useMediaQuery from '#hooks/useMediaQuery';
import useNavigation from '#hooks/useNavigation';
import useForm from '#hooks/useForm';
import useNotifications from '#hooks/useNotifications';
import useOrders from '#hooks/useOrders';
import useOptions from '#hooks/useOptions';
import useSubscriptions from '#hooks/useSubscriptions';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';

import Section from '#components/dashboard/Section';
import OrderForm, {
  TABLE_KEYS,
  SELECTION_TABLE_KEYS,
  defaultTableKeys,
  defaultSelectionTableKeys,
} from '#components/orders/OrderForm';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.orders.createOrder;
const localeButtonKeys = locale.keys.buttons;
const localeFormKeys = locale.keys.forms.orders;
const localeNotificationKeys = locale.keys.notifications;

const tableKeys = [...defaultTableKeys].filter(
  (key) => key !== TABLE_KEYS.id,
);
const tabletTableKeys = [...tableKeys].filter(
  (key) => key !== TABLE_KEYS.productSku
    && key !== TABLE_KEYS.unit,
);
const selectionTableKeys = [...defaultSelectionTableKeys].filter(
  (key) => key !== SELECTION_TABLE_KEYS.id,
);
const tabletSelectionTableKeys = [...selectionTableKeys].filter(
  (key) => key !== SELECTION_TABLE_KEYS.unit,
);

interface CreateOrderProps {
  onSave? : (order : ProjectedOrder) => void;
  onCancel? : () => void;
}

interface CreateOrderControlProps extends CreateOrderProps {
  order : ProjectedOrder;
}

function CreateOrderControl({
  order : initialOrder,
  onSave,
  onCancel,
} : CreateOrderControlProps) {
  const { isDesktop } = useMediaQuery();
  const { navigate } = useNavigation();
  const { createNotification } = useNotifications();
  const { state : order, dispatch, valid} = useForm<ProjectedOrder>();
  const {
    evaluateOptions,
    generateOrderUrl,
  } = useOrders();
  const {
    findProjectedOrder,
    buildLineItemSubscription,
    bulkCreateRecurringLineItems,
  } = useSubscriptions();

  const [services, setServices] = useState<Service[]>([]);
  const [exisingOrder, setExistingOrder] = useState<DraftOrder | null>(null);
  const [formReady, setFormReady] = useState(false);

  const match = useCallback(async () => {
    if (!order) return;
    setExistingOrder(await findProjectedOrder({
      ...order,
      iteration : order.timeSlotIteration,
      division : order.timeSlotDivision,
    }));
  }, [order, findProjectedOrder]);

  const handleSave = useCallback(async () => {
    if (!order || !valid) return;

    const result = await bulkCreateRecurringLineItems({
      lineItems : Object.values(order.lineItems).map((lineItem) => ({
        ...lineItem,
        id : undefined,
        refId : lineItem.id,
        customerId : order.customer?.id ?? NaN,
        serviceChannelId : order.serviceChannel?.id ?? NaN,
        locationId : order.location?.id ?? NaN,
        addressId : order.address?.id ?? NaN,
        timeSlotId : order.timeSlot?.id ?? NaN,
        timeSlotIteration : order.timeSlotIteration,
        timeSlotDivision : order.timeSlotDivision,
      })),
      selections : Object.values(order.selections).map((selection) => ({
        ...selection,
        id : undefined,
      })),
      subscriptions : Object.values(order.subscriptions).map((sub) => ({
        ...sub,
        id : undefined,
        customerId : order.customer?.id ?? NaN,
        serviceChannelId : order.serviceChannel?.id ?? NaN,
        locationId : order.location?.id ?? NaN,
        addressId : order.address?.id ?? NaN,
        timeSlotId : order.timeSlot?.id ?? NaN,
        timeSlotDivision : order.timeSlotDivision,
        startIteration : order.timeSlotIteration,
        targetIteration : order.timeSlotIteration,
      })),
    });
    const lineItems = result?.lineItems ?? null;
    const selections = result?.selections ?? null;
    const subscriptions = result?.subscriptions ?? null;

    const subscriptionErrorCount = Object.values(order.subscriptions).length
      - listRecords(subscriptions).length

    if (lineItems && selections && !subscriptionErrorCount) {
      createNotification({
        key : 'create-order-success',
        message : localize(localeNotificationKeys.orders.create.success),
        icon : <Icon icon={settings.svgIcons.receipt} />,
        colour : settings.colours.alert.primary,
      });
      const orderUrl = generateOrderUrl(
        order,
        { relative : true, searchPrefix : 'search' },
      );
      navigate(orderUrl);
    } else {
      if (!lineItems) {
        createNotification({
          key : 'create-order-error',
          message : localize(localeNotificationKeys.orders.create.error),
          icon : <Icon icon={settings.svgIcons.receipt} />,
          colour : settings.colours.alert.alert,
        })
      } else if (!selections) {
        createNotification({
          key : 'create-selections-error',
          message : localize(localeNotificationKeys.orders.customise.error),
          icon : <Icon icon={settings.svgIcons.receipt} />,
          colour : settings.colours.alert.alert,
        });
      } else if (subscriptionErrorCount) {
        createNotification({
          key : 'create-subscriptions-error',
          message : localize(localeNotificationKeys.orders.subscribe.error),
          icon : <Icon icon={settings.svgIcons.receipt} />,
          count : subscriptionErrorCount,
          colour : settings.colours.alert.alert,
        });
      }
    }
    if (onSave) onSave(order);
  }, [
    order,
    valid,
    onSave,
    generateOrderUrl,
    bulkCreateRecurringLineItems,
    createNotification,
    navigate,
  ]);

  const handleAddLineItem = useCallback((
    lineItem : LineItem,
    selections? : Selection[],
    subscriptionOption? : { period? : number },
  ) => {
    if (!order) return;
    if (lineItem.quantity === 0) return;

    if (lineItem.id !== undefined) return;
    const itemId = Math.min(0, ...Object.keys(order.lineItems).map(Number)) - 1;
    lineItem.id = itemId;

    let selectId = Math.min(0, ...Object.keys(order.selections).map(Number));
    let subId = Math.min(0, ...Object.keys(order.subscriptions).map(Number));
    dispatch({
      lineItems : {
        ...order.lineItems,
        [lineItem.id] : lineItem,
      },
      ...(selections && {
          selections : selections.reduce((acc, selection) => ({
            ...acc,
            ...({[selection.id ?? --selectId] : {
              ...selection,
              lineItemId : itemId,
            }}),
          }), order.selections),
      }),
      ...(subscriptionOption?.period && {
        subscriptions : {
          ...order.subscriptions,
          [--subId] : {
            ...buildLineItemSubscription(lineItem, [], subscriptionOption),
            id : subId,
          },
        },
      }),
    });
  }, [dispatch, order, buildLineItemSubscription]);

  const handleRemoveLineItem = useCallback((lineItem : LineItem) => {
    if (!order || lineItem.id === undefined) return;
    const {
      [lineItem.id] : removed,
      ...lineItems
    } = order.lineItems;
    const selections = Object.values(order.selections).filter(
      (selection) => selection.lineItemId !== lineItem.id
    );
    dispatch({ lineItems, selections });

    return true;
  }, [dispatch, order]);

  const handleUpdateLineItem = useCallback((
    lineItem : LineItem,
    selections? : Selection[],
    subscriptionOption? : { period? : number },
  ) => {
    if (!order || lineItem.id === undefined) return;

    const subscription = Object.values(order.subscriptions).find(
      (sub) => sub.lineItemId === lineItem.id
    );

    dispatch({
      lineItems : {
        ...order.lineItems,
        [lineItem.id] : lineItem,
      },
      ...(selections && {
        selections : {
          ...Object.values(order.selections).filter(
            (selection) => selection.lineItemId !== lineItem.id
          ),
          ...selections.reduce((acc, selection) => ({
            ...acc,
            ...(selection.id !== undefined && {[selection.id] : selection}),
          }), {}),
        },
      }),
    })

    if (subscriptionOption?.period === undefined) return;
    if (subscription) {
      if (subscriptionOption.period === 0) {
        const {
          [subscription.id ?? NaN] : removed,
          ...subscriptions
        } = order.subscriptions;
        dispatch({ subscriptions });
      } else {
        dispatch({
          subscriptions : {
            ...order.subscriptions,
            [subscription.id ?? NaN] : {
              ...subscription,
              quantity : lineItem.quantity,
              period : subscriptionOption.period,
            },
          },
        });
      }
    } else if (subscriptionOption.period > 0) {
      const i = Math.min(0, ...Object.keys(order.subscriptions).map(Number));
      dispatch({
        subscriptions : {
          ...order.subscriptions,
          [i - 1] : {
            ...buildLineItemSubscription(lineItem, [], subscriptionOption),
            id : i - 1,
          }
        },
      });
    }
  }, [dispatch, order, buildLineItemSubscription]);

  useEffect(() => {
    if (!order) {
      setServices([]);
      return;
    }
    const { services } = evaluateOptions(order);
    setServices(services);
  }, [order, evaluateOptions]);

  useEffect(() => { match(); }, [match]);

  return (
    <Section
      title={localize(localeContentKeys.title)}
      text={localize(localeContentKeys.body)}
    >
      <Banner
        icon={valid
          ? (exisingOrder
            ? (<Icon icon={settings.svgIcons.info} />)
            : (<Icon icon={settings.svgIcons.check} />))
          : (<Icon icon={settings.svgIcons.clear} />)
        }
        actions={exisingOrder
          ? (
            <Button
              href={
                generateOrderUrl(
                  exisingOrder,
                  { relative : true, searchPrefix : 'search' },
                )
              }
            >
              { exisingOrder.paid
                ? localize(localeButtonKeys.view)
                : localize(localeButtonKeys.edit)
              }
            </Button>
          )
          : undefined
        }
        colour={valid
          ? (exisingOrder
            ? exisingOrder.paid
              ? settings.colours.alert.alert
              : settings.colours.alert.secondary
            : settings.colours.alert.primary)
          : settings.colours.alert.alert
        }
      >
        { valid
          ? (exisingOrder
            ? exisingOrder.paid
              ? localize(localeContentKeys.paidOrder)
              : localize(localeContentKeys.existingOrder)
            : localize(localeContentKeys.fulfillingServices) +
              ` ${services.map((service) => service.name).join(', ')}.`)
          : localize(localeContentKeys.invalidOptions)
        }
      </Banner>
      <OrderForm
        order={order ?? initialOrder}
        mode="edit"
        freeEdit
        itemsReady={formReady}
        setItemsReady={setFormReady}
        onAddLineItem={handleAddLineItem}
        onRemoveLineItem={handleRemoveLineItem}
        onUpdateLineItem={handleUpdateLineItem}
        tableKeys={isDesktop ? tableKeys : tabletTableKeys}
        selectionTableKeys={
          isDesktop
            ? selectionTableKeys
            : tabletSelectionTableKeys
        }
      />
      <Button onClick={handleSave} disabled={exisingOrder?.paid || !formReady}>
        { localize(localeButtonKeys.save) }
      </Button>
      <Button onClick={onCancel} colour={settings.colours.button.alert}>
        { localize(localeButtonKeys.cancel) }
      </Button>
    </Section>
  );
}

function CreateOrder(props : CreateOrderProps) {
  const { evaluateOptions } = useOrders();
  const { createDefaultOrder } = useOptions();

  const [order, setOrder] = useState<ProjectedOrder | null>();

  const build = useCallback(async () => {
    setOrder({
      ...createDefaultOrder(),
      subscriptions : {},
      service : null
    });
  }, [createDefaultOrder, setOrder]);

  const validateOrder = useCallback(
    (order : ProjectedOrder) : { [key : string] : string } | null => {
      const { errors, valid } = evaluateOptions(order);
      if (valid) return null;
      return {
        ...(errors.address && {
          address : localize(localeFormKeys.errors.invalidAddress),
        }),
        ...(errors.serviceChannel && {
          serviceChannel : localize(
            localeFormKeys.errors.invalidServiceChannel
          ),
        }),
        ...(errors.location && {
          location : localize(localeFormKeys.errors.invalidLocation),
        }),
        ...(errors.timeSlot && {
          timeSlot : localize(localeFormKeys.errors.invalidTimeSlot),
        }),
      }
    },
    [evaluateOptions],
  );

  const requireFulfillment = useCallback(
    (order : ProjectedOrder) : { [key : string] : string } | null => {
      const { addresses, locations } = evaluateOptions(order);
      if (!order.address && !order.location) return {
        address : localize(localeFormKeys.errors.fulfillmentRequired),
        location : localize(localeFormKeys.errors.fulfillmentRequired),
      };
      if (
        !addresses.length &&
        !!locations.length &&
        !order.location
      ) return {
        location : localize(localeFormKeys.errors.locationRequired),
        ...(!!order.address && {
          address : localize(localeFormKeys.errors.addressNotAllowed),
        }),
      };
      if (
        !!addresses.length &&
        !locations.length &&
        !order.address
      ) return {
        address : localize(localeFormKeys.errors.addressRequired),
        ...(!!order.location && {
          location : localize(localeFormKeys.errors.locationNotAllowed),
        }),
      };
      return null;
    },
    [evaluateOptions],
  );

  useEffect(() => { build();}, [build]);

  if (order === undefined) return null;
  if (order === null) return (
    <Section
      title={localize(localeContentKeys.title)}
      text={localize(localeContentKeys.insuffientConfig)}
    />
  )
  return (
    <FormProvider
      init={order}
      validators={[
        validateOrder,
        requireFulfillment,
      ]}
    >
      <CreateOrderControl {...props} order={order} />
    </FormProvider>
  );
}

export default CreateOrder;
