import React from 'react';
import Typography from '@mui/material/Typography';

import useConfig from '#hooks/useConfig';

import {
  Component,
  TypographyVariant,
  Colour,
  Alignment,
  Spacing,
  Font,
  convert,
  settings,
} from '#materials/types'

interface TextProps {
  component? : Component;
  variant? : TypographyVariant;
  font? : Font;
  colour? : Colour;
  align? : Alignment;
  spacing? : Spacing;
  adjustBaseline? : boolean;
  noWrap? : boolean;
  children : React.ReactNode;
}

function Text(props : TextProps) {
  const { config } = useConfig();

  return (
    <Typography
        component={props.component ? props.component : 'span'}
        variant={props.variant}
        fontFamily={config.brand.fonts[props.font ? props.font : 'default']}
        color={props.colour ? props.colour : 'inherit'}
        align={convert.textAlignment(props.align)}
        noWrap={props.noWrap}
        width={props.noWrap ? '100%' : 'auto'}
        sx={{
          display : 'block',
          px : [2],
          whiteSpace : 'pre-wrap',
          ...(props.spacing !== settings.spacings.dense && { mb : [2] }),
          ...(props.adjustBaseline && { pt : '0.35rem' })
        }}
    >
        { props.children }
    </Typography>
  );
}

export default Text;
