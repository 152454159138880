import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { CreditCard, Customer } from '#types';

import useNotifications from '#hooks/useNotifications';
import useCards from '#hooks/useCards';

import { settings } from '#materials';
import Text from '#materials/Text';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';
import { TableActionCell, Action } from '#materials/TableCell';

import Section from '#components/dashboard/Section';
import CreditCardTable from '#components/cards/CreditCardTable';

import { listRecords } from '#utils/data';
import { copy } from '#utils/clipboard';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.customers.customerCards;
const localeButtonKeys = locale.keys.buttons;
const localeTableKeys = locale.keys.tables.cards;
const localeNotificationKeys = locale.keys.notifications.cards;

interface CustomerCardsProps {
  customer : Customer;
}

function CustomerCards({ customer } : CustomerCardsProps) {
  const { createNotification } = useNotifications();
  const {
    load: loadCards,
    creditCards,
    deleteCreditCard,
    generateRegistrationLink,
  } = useCards();

  const [link, setLink] = useState('');
  const [removeTartget, setRemoveTarget] = useState<CreditCard | null>(null);

  const cards = useMemo(
    () => listRecords(creditCards).filter(c => c.customerId === customer.id),
    [customer, creditCards],
  );

  const copyLink = useCallback(async () => {
    const newLink = generateRegistrationLink(customer);
    const success = await copy(newLink);
    if (success) {
      createNotification({
        key : 'copy-link-success',
        message : localize(localeNotificationKeys.copyLink.success),
        icon : (<Icon icon={settings.svgIcons.person} />),
        colour : settings.colours.alert.primary,
      });
      setLink('');
    } else {
      createNotification({
        key : 'copy-link-error',
        message : localize(localeNotificationKeys.copyLink.error),
        icon : (<Icon icon={settings.svgIcons.person} />),
        colour : settings.colours.alert.alert,
      });
      setLink(newLink)
    }
  }, [customer, setLink, generateRegistrationLink, createNotification]);

  const handleInitRemove = useCallback((card : CreditCard) => () => {
    setRemoveTarget(card);
  }, [setRemoveTarget]);

  const handleCancelRemove = useCallback(() => {
    setRemoveTarget(null);
  }, [setRemoveTarget]);

  const handleRemove = useCallback(async () => {
    if (!removeTartget) return;

    const success = await deleteCreditCard(removeTartget);
    if (success) {
      createNotification({
        key : 'remove-card-success',
        message : localize(localeNotificationKeys.remove.success),
        icon : (<Icon icon={settings.svgIcons.person} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'remove-card-error',
        message : localize(localeNotificationKeys.remove.error),
        icon : (<Icon icon={settings.svgIcons.person} />),
        colour : settings.colours.alert.alert,
      });
    }
    setRemoveTarget(null);
  }, [removeTartget, deleteCreditCard, createNotification]);

  const generateActions = useCallback((card : CreditCard) => {
    return (
      <TableActionCell>
        <Action
          label={
            `${localize(localeTableKeys.actions.delete)} ${card.id}`
          }
          onClick={handleInitRemove(card)}
          disabled={removeTartget !== null}
          colour={settings.colours.button.alert}
        >
          <Icon icon={settings.svgIcons.delete}/>
        </Action>
      </TableActionCell>
    );
  }, [removeTartget, handleInitRemove]);

  useEffect(() => { loadCards(); }, [loadCards]);

  return (
    <Section
      title={localize(localeContentKeys.title)}
      text={localize(localeContentKeys.text)}
    >
      { removeTartget && (
        <Banner
          icon={(<Icon icon={settings.svgIcons.delete} />)}
          actions={(
            <>
              <Button onClick={handleRemove}>
                { localize(localeButtonKeys.delete) }
              </Button>
              <Button onClick={handleCancelRemove}>
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          )}
          colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.confirmDelete) }
        </Banner>
      ) }
      <CreditCardTable cards={cards} generateActions={generateActions} />
      <Button
        onClick={copyLink}
        >
        { localize(localeButtonKeys.copy) + ' ' +
          localize(localeContentKeys.newLink) }
      </Button>
      { link && <Text font={settings.fonts.mono}>{ link }</Text> }
    </Section>
  );
}

export default CustomerCards;
