import React from 'react';

import Segment from '#materials/Segment';
import Card from '#materials/Card';
import Text from '#materials/Text';
import Link from '#materials/Link';
import { settings } from '#materials';

interface SectionProps {
  title? : string;
  titleHref? : string;
  text? : string;
  children? : React.ReactNode;
}

function Section({ title, titleHref, text, children } : SectionProps) {

  return (
    <Card>
      { title &&
        <Text component={settings.components.h3}>
          { titleHref
            ? (<Link href={titleHref} newTab>{ title }</Link>)
            : title
          }
        </Text>
      }
      { text &&
        <Text
          component={settings.components.h3}
          variant={settings.variants.typography.body2}
        >
          { text }
        </Text>
      }
      { !!children &&
        <Segment >
          { children }
        </Segment>
      }
    </Card>
  );
}

export default Section;
