import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  LineItem,
  Assembly,
  Product,
  Fulfilment,
  Selection,
  DraftCustomOrder,
} from '#types';

import useOptions from '#hooks/useOptions';
import useProducts from '#hooks/useProducts';

import { settings } from '#materials';
import {
  CellElement,
  TableTextInputCell,
  TableSelectCell,
  TableCell,
} from '#materials/TableCell';

import { listRecords } from '#utils/data';
import { formatCurrency } from '#utils/currency';
import locale, { localize } from '#utils/locale';
import { OrderFormMode } from '#components/orders/OrderForm';

const localeTableKeys = locale.keys.tables.selections;

export const SELECTION_TABLE_KEYS = {
  id : 'id',
  assembly : 'assembly',
  product : 'product',
  quantity : 'quantity',
  unit : 'unit',
  price : 'price',
  actions : 'actions',
} as const;
export type SelectionTableKey =
  typeof SELECTION_TABLE_KEYS[keyof typeof SELECTION_TABLE_KEYS];
export const defaultSelectionTableKeys = Object.values(SELECTION_TABLE_KEYS);

function generateAssemblyLabel(assembly : Assembly) { return assembly.name };
function generateProductLabel(product : Product) { return product.name };

function generateAssemblyKey(assembly : Assembly) {
  return `${assembly.id}`
}
function generateProductKey(product : Product) {
  return `${product.id}`
}

interface SelectionRowProps {
  lineItem : LineItem;
  selection? : Selection | null;
  fulfilment? : Fulfilment | null;
  order? : DraftCustomOrder;
  assembly? : Assembly | null;
  mode? : OrderFormMode;
  disabled? : boolean;
  tableKeys? : SelectionTableKey[];
  setSelection? : (selection : Selection) => void;
  onUpdateFulfilment? : (fulfilment : Fulfilment) => void | Promise<void>;
  generateActions? : (
    selection : Selection | null,
    fulfilment? : Fulfilment | null,
  ) => CellElement;
}

function SelectionRow({
  lineItem,
  selection,
  fulfilment,
  assembly,
  order,
  mode = 'edit',
  disabled = false,
  tableKeys = defaultSelectionTableKeys,
  setSelection,
  onUpdateFulfilment,
  generateActions,
} : SelectionRowProps) {
  const {
    assemblies : allAssemblies,
    getProductAssemblies,
    getAssemblyCollections,
    getCollectionProducts,
    getSelectionFulfilment,
    calculateSelectionPrice,
  } = useOptions();
  const { products : allProducts } = useProducts();

  const selectionFulfilment = useMemo(() => (
    fulfilment ?? ((order && selection)
      ? getSelectionFulfilment(selection, order)
      : null)
  ), [fulfilment, order, selection, getSelectionFulfilment]);

  const lineItemProduct = useMemo(() => listRecords(allProducts).find(
    product => product.id === lineItem.productId
  ), [lineItem, allProducts]);

  const assemblies = useMemo(() => lineItemProduct
    ? getProductAssemblies(lineItemProduct)
    : [],
  [lineItemProduct, getProductAssemblies]);

  const [selectionAssembly, setAssembly] = useState<Assembly | null>(
    assembly
      ?? listRecords(allAssemblies).find(a => a.id === selection?.assemblyId)
      ?? null
  );
  const [products, setProducts] = useState<Product[]>(selectionAssembly
      ? getAssemblyCollections(selectionAssembly)
        .map((c) => getCollectionProducts(c))
        .reduce(
          (acc, products) => [...acc, ...products],
          [],
        ) : listRecords(allProducts)
  );
  const [product, setProduct] = useState<Product | null>(
    listRecords(allProducts).find(p => (
      p.id === (mode === 'fulfilment'
        ? (selectionFulfilment?.fulfilledProductId
          ?? selectionFulfilment?.requestedProductId
          ?? selection?.productId)
        : selection?.productId)
    )) ?? null
  );
  const [quantity, setQuantity] = useState(
    (mode === 'fulfilment'
      ? (selectionFulfilment?.fulfilledQty
        ?? selectionFulfilment?.requestedQty
        ?? selection?.quantity)
      : selection?.quantity) ?? null
  );

  const getAssemblyProducts = useCallback((assembly : Assembly) => {
    const collections = getAssemblyCollections(assembly);
    setProducts(collections.map((c) => getCollectionProducts(c)).reduce(
      (acc, products) => [...acc, ...products],
      [],
    ));
  }, [getAssemblyCollections, getCollectionProducts]);

  const handleProduct = useCallback(async (product : Product) => {
    if (!product || !product.id) return;
    setProduct(product);

    if (mode === 'fulfilment') {
      if (!selectionFulfilment) return;
      await onUpdateFulfilment?.({
        ...selectionFulfilment,
        fulfilledProductId : product.id,
      });
      return;
    }

    if (selection) setSelection?.({ ...selection, productId : product.id });
  }, [selection, setSelection, mode, selectionFulfilment, onUpdateFulfilment]);

  const handleAssembly = useCallback(async (assembly : Assembly) => {
    if (!assembly || !assembly.id) return;
    setAssembly(assembly);
    if (selection) setSelection?.({ ...selection, assemblyId : assembly.id });
    getAssemblyProducts(assembly);
  }, [selection, setSelection, getAssemblyProducts]);

  const handleQuantity = useCallback((quantity : number | null) => {
    if (mode === 'fulfilment') setQuantity(quantity);
    if (selection) setSelection?.({ ...selection, quantity : quantity ?? 1 });
  }, [selection, setSelection, mode]);

  const handleUpdateFulfilmentOnBlur = useCallback(async () => {
    if (
      onUpdateFulfilment
        && selectionFulfilment
        && (quantity !== selectionFulfilment?.fulfilledQty)
    ) {
      const updatedFulfilment = {
        ...selectionFulfilment,
        fulfilledQty : quantity,
      }
      await onUpdateFulfilment(updatedFulfilment);
    }
  }, [
    selectionFulfilment,
    quantity,
    onUpdateFulfilment,
  ]);

  const rowGenerator = useCallback(() => {
    const greyOut = (mode === 'fulfilment') && !selectionFulfilment;
    const price = selectionFulfilment?.unitPrice
      ?? (selection ? calculateSelectionPrice(selection) : null);
    const hangingFulfilment = (mode === 'fulfilment')
      && !selection
      && selectionFulfilment
      && selectionFulfilment.fulfilledQty === 0
      && !assembly;

    return tableKeys.map((key) => {
      switch (key) {
        case SELECTION_TABLE_KEYS.id: return (
          <TableCell
            key={key}
            faded={greyOut}
            width={
              mode === 'fulfilment'
                ? settings.dimensions.small
                : settings.dimensions.xsmall
            }
          >
            { (selection?.id || selectionFulfilment?.id)
              ? '# ' + [selection?.id ?? lineItem.id, selectionFulfilment?.id]
                .filter(id => !!id)
                .join('-')
              : ''
            }
          </TableCell>
        );
        case SELECTION_TABLE_KEYS.assembly: return (
          disabled
            ? (
              <TableCell
                key={key}
                faded={greyOut}
                width={settings.dimensions.small}
              >
                { selectionAssembly?.name
                  ?? localize(localeTableKeys.defaults.assembly) }
              </TableCell>
            ) : (
              <TableSelectCell
                key={key}
                label={localize(localeTableKeys.headings.assembly)}
                selected={selectionAssembly}
                options={assemblies || []}
                onChange={handleAssembly}
                disabled={mode === 'fulfilment' ? true : false}
                isEqual={(a, b) => a.id === b.id}
                labelGenerator={assembly => generateAssemblyLabel(assembly)}
                keyGenerator={generateAssemblyKey}
                width={settings.dimensions.medium}
              />
            )
        );
        case SELECTION_TABLE_KEYS.product: return (
          disabled
            ? (
              <TableCell
                key={key}
                faded={greyOut}
                width={settings.dimensions.small}
              >
                { product?.name ?? localize(localeTableKeys.defaults.product) }
              </TableCell>
            ) : (
              <TableSelectCell
                key={key}
                label={localize(localeTableKeys.headings.product)}
                selected={!hangingFulfilment ? product : null}
                options={products || []}
                onChange={handleProduct}
                labelGenerator={product =>
                  String(generateProductLabel(product))}
                keyGenerator={generateProductKey}
                width={settings.dimensions.medium}
                disabled={disabled
                  || hangingFulfilment
                  || (mode === 'fulfilment' && !selectionFulfilment)}
              />
            )
        );
        case SELECTION_TABLE_KEYS.quantity: return (
          <TableTextInputCell
            key={key}
            id={`${selection?.id}-quantity`}
            label={localize(localeTableKeys.headings.quantity)}
            value={quantity}
            onChange={handleQuantity}
            onBlur={handleUpdateFulfilmentOnBlur}
            inputType={settings.inputType.number}
            inputFormat={settings.inputFormat.float}
            disabled={disabled
              || hangingFulfilment
              || (mode === 'fulfilment' && !selectionFulfilment)}
            faded={greyOut}
            alignment={settings.alignments.right}
            width={settings.dimensions.xsmall}
          />
        );
        case SELECTION_TABLE_KEYS.unit:
          return (<TableCell key={key} faded={greyOut}>
            { price ? `(× ${formatCurrency(price)})` : ''}
          </TableCell>)
        case SELECTION_TABLE_KEYS.price:
          const total = price
            ? {
              ...price,
              amount : price.amount * (quantity ?? 1),
              calculatedValue : price.calculatedValue * (quantity ?? 1),
            }
            : null
          return (
            <TableCell
              key={key}
              width={settings.dimensions.xsmall}
              faded={greyOut}
            >
              { total ? formatCurrency(total) : '' }
            </TableCell>
          );
        case SELECTION_TABLE_KEYS.actions : return (
          <React.Fragment key={key}>
            { generateActions?.(selection ?? null, selectionFulfilment) }
          </React.Fragment>
        )
        default: return <TableCell key={key}/>;
      }
    });
  }, [
    disabled,
    lineItem,
    selection,
    assembly,
    tableKeys,
    mode,
    product,
    selectionFulfilment,
    selectionAssembly,
    assemblies,
    products,
    handleAssembly,
    handleProduct,
    handleQuantity,
    generateActions,
    calculateSelectionPrice,
    handleUpdateFulfilmentOnBlur,
    quantity
  ]);

  const row = useMemo(() => rowGenerator(), [rowGenerator]);

  useEffect(() => {
    setQuantity(
      (mode === 'fulfilment'
        ? (selectionFulfilment?.fulfilledQty
          ?? selectionFulfilment?.requestedQty
          ?? selection?.quantity
          ?? null)
        : selection?.quantity) ?? null
    );
  }, [selectionFulfilment, selection, mode]);

  return row;
}

export default SelectionRow;
