import { useCallback, useContext } from 'react';

import AuthContext from '#mrktbox/clerk/context/AuthContext';

import useStorage from '#mrktbox/clerk/hooks/useStorage';
import useIndexedDB from '#mrktbox/clerk/hooks/useIndexedDB';
import useAuthAPI from '#mrktbox/clerk/hooks/api/useAuthAPI';

const checkAuthDefault = {
  permissions : [] as string[] | undefined,
}

function useUsers() {
  const {
    user,
    token,
    dispatchUser,
    clear,
  } = useContext(AuthContext);

  const { clearCache : clearStorage } = useStorage();
  const { clearCache : clearDB } = useIndexedDB();
  const {
    login,
    verify,
    retrievePasswordResetToken,
    resetPassword,
    sendPasswordReset,
  } = useAuthAPI();

  const loginAndVerify = useCallback(
    async (username : string, password : string, permissions = []) => {
      const { authorized : loginAuth, token } = await login(
        username,
        password,
      );
      if (loginAuth === null) return { user : null, error : true };
      if (!token) return { user : null, error : false };

      const { authorized : verifyAuth, user } = await verify(token);

      if (user) {
        for (const permission of permissions) {
          if (!(user.permissions.includes(permission))) {
            return { user : null, error : false };
          }
        }
        dispatchUser(user, token);
      }

      return {
        user,
        error : verifyAuth === null,
      };
    },
    [login, verify, dispatchUser],
  );

  const logout = useCallback(async () => {
    clearStorage();
    clearDB();
    clear();
    window.location.reload();
  }, [clear, clearDB, clearStorage]);

  const getResetToken = useCallback(async (username : string) => {
    return await retrievePasswordResetToken(username);
  }, [retrievePasswordResetToken]);

  const changePassword = useCallback(
    async (username : string, password : string, token : string) => {
      return await resetPassword(username, password, token);
    },
    [resetPassword],
  );

  const checkAuth = useCallback(({
    permissions
  } = checkAuthDefault) => {
    return (
      !!user &&
      (!permissions ||
        permissions.every(
          (permission) => user.permissions.includes(permission)
        ))
    );
  }, [user]);

  return {
    user,
    token,
    login : loginAndVerify,
    logout,
    retrievePasswordResetToken : getResetToken,
    resetPassword : changePassword,
    sendPasswordReset,
    checkAuth,
  };
}

export default useUsers;
