import { useCallback, useRef } from 'react';

import { actionTypes, DataAction, DataIndex } from '#mrktbox/clerk/types';

import useLoading from '#mrktbox/clerk/hooks/useLoading';
import useStorage from '#mrktbox/clerk/hooks/useStorage';
import useIndexedDB from '#mrktbox/clerk/hooks/useIndexedDB';

export { actionTypes } from '#mrktbox/clerk/hooks/useStorage';
export type { DataIndex } from '#mrktbox/clerk/types';
export type { ActionType } from '#mrktbox/clerk/hooks/useStorage';

type Record = { id? : number };

type NullTransaction<Opts, Out> = ((options? : Opts) => Promise<Out | null>)
type NullAuxTransaction<Opts, Out, Add> =
  ((options? : Opts) => Promise<[Out | null, Add]>)
type Transaction<In, Opts, Out> =
  ((input : In, options? : Opts) => Promise<Out | null>)
type AuxTransaction<In, Opts, Out, Add> =
  ((input : In, options? : Opts) => Promise<[Out | null, Add]>)
type DualTransaction<A, B, Opts, Out> =
  ((inputA : A, inputB : B, options? : Opts) => Promise<Out | null>)
type DualAuxTransaction<A, B, Opts, Out, Add> =
  ((inputA : A, inputB : B, options? : Opts) => Promise<[Out | null, Add]>)
type TransactionCallback<R> = (result : R) => any;
type AuxTransactionCallback<R, Add = any> =
  (result : R, additional? : Add) => any;

function dispatchSet<R extends Record>(
  dispatch : React.Dispatch<DataAction<R>>,
  records : DataIndex<R> | null,
) {
  if (records === null) dispatch({ data : {}, type : actionTypes.add });
  else dispatch({ data : records, type : actionTypes.update });
}

function dispatchAdd<R extends Record>(
  dispatch : React.Dispatch<DataAction<R>>,
  record : R | null,
  id? : number,
) {
  const indexId = record?.id ?? id;
  if (indexId === undefined) return;
  dispatch({ data : { [indexId] : record }, type : actionTypes.add });
}

function dispatchBulkAdd<R extends Record>(
  dispatch : React.Dispatch<DataAction<R>>,
  records : DataIndex<R> | null,
) {
  dispatch({ data : records ?? {}, type : actionTypes.add });
}

function dispatchRemove<R extends Record>(
  dispatch : React.Dispatch<DataAction<R>>,
  record : R | null,
) {
  if (!record?.id) return;
  dispatch({ data : { [record.id] : record }, type : actionTypes.remove });
}

function dispatchBulkRemove<R extends Record>(
  dispatch : React.Dispatch<DataAction<R>>,
  records : DataIndex<R>,
) {
  dispatch({ data : records, type : actionTypes.remove });
}

interface useTransactionProps<In, Out, Options> {
  dispatch : (result : Out, input? : In) => void;
  transaction? : Transaction<In, Options, Out>;
  transactionCallback? : TransactionCallback<Out>;
  dipatchInput? : boolean;
}

export function useTransaction<In, Out, Options = any>({
  dispatch,
  transaction,
  transactionCallback,
} : useTransactionProps<In, Out, Options>) {
  return useCallback(
    async <Opt extends Options>(input : In, options? : Opt) => {
      if (!transaction) return null;
      const result = await transaction(input, options);
      if (result === null) return null;

      dispatch(result, input);
      if (transactionCallback) await transactionCallback(result);
      return result;
    },
    [transaction, transactionCallback, dispatch],
  );
}

export interface useDualTransactionProps<A, B, Options, Out> {
  dispatch : (result : Out, input? : A) => void;
  transaction? : DualTransaction<A, B, Options, Out>;
  transactionCallback? : TransactionCallback<Out>;
}

export function useDualTransaction<A, B, Options = any, Out = any>({
  dispatch,
  transaction,
  transactionCallback,
} : useDualTransactionProps<A, B, Options, Out>) {
  return useCallback(
    async <Opt extends Options>(inputA : A, inputB : B, options? : Opt) => {
      if (!transaction) return null;
      const result = await transaction(inputA, inputB, options);
      if (result === null) return null;

      dispatch(result, inputA);
      if (transactionCallback) await transactionCallback(result);
      return result;
    },
    [transaction, transactionCallback, dispatch],
  );
}

interface useTransactionWithReturnProps<In, Options, Out, Returns> {
  dispatch : (data : Out, input? : In) => void;
  transaction? : AuxTransaction<In, Options, Out, Returns>;
  transactionCallback? : AuxTransactionCallback<Out, Returns>;
}

export function useTransactionWithReturn<In, Opt, Out, Returns>({
  dispatch,
  transaction,
  transactionCallback,
} : useTransactionWithReturnProps<In, Opt, Out, Returns>) {
  return useCallback(
    async <O extends Opt>(input : In, options? : O) => {
      if (!transaction) return [null, null] as [null, Returns];
      const [result, add] = await transaction(input, options);
      if (result === null) {
        return [null, add] as [null, Returns];
      }

      dispatch(result, input);
      if (transactionCallback) await transactionCallback(result, add);
      return [result, add] as [Out, Returns];
    },
    [transaction, transactionCallback, dispatch],
  );
}

interface useDualTransactionWithReturnProps<A, B, Options, Out, Returns> {
  dispatch : (data : Out) => void;
  transaction? : DualAuxTransaction<A, B, Options, Out, Returns>;
  transactionCallback? : AuxTransactionCallback<Out, Returns>;
}

export function useDualTransactionWithReturn<A, B, Options, Out, Returns>({
  dispatch,
  transaction,
  transactionCallback,
} : useDualTransactionWithReturnProps<A, B, Options, Out, Returns>) {
  return useCallback(
    async <O extends Options>(inputA : A, inputB : B, options? : O) => {
      if (!transaction) return [null, null] as [null, Returns];
      const [result, add] = await transaction(inputA, inputB, options);
      if (result === null) {
        return [null, add] as [null, Returns];
      }

      dispatch(result);
      if (transactionCallback) await transactionCallback(result, add);
      return [result, add] as [Out, Returns];
    },
    [transaction, transactionCallback, dispatch],
  );
}

interface useNullLoaderProps<Options, Out> {
  dispatch : (result : Out | null) => void;
  loader? : NullTransaction<Options, Out>;
  loaderCallback? : TransactionCallback<Out | null>;
}

export function useNullLoader<Options, Out>({
  dispatch,
  loader,
  loaderCallback,
} : useNullLoaderProps<Options, Out>) {
  const { load } = useLoading<Out | null>();

  return useCallback(
    async <Opt extends Options>(options? : Opt) => {
      if (!loader) return null;
      const [result] = await load(
        async () => loader(options),
        async (result) => {
          dispatch(result);
          if (loaderCallback) await loaderCallback(result);
        }
      );
      return result;
    },
    [loader, loaderCallback, dispatch, load],
  );
}

interface useLoaderProps<In, Options, Out> {
  dispatch : (result : Out | null, input? : In) => void;
  loader? : Transaction<In, Options, Out>;
  loaderCallback? : TransactionCallback<Out | null>;
}

export function useLoader<In, Options, Out>({
  dispatch,
  loader,
  loaderCallback,
} : useLoaderProps<In, Options, Out>) {
  const { load } = useLoading<Out | null>();

  return useCallback(
    async <Opt extends Options>(input : In, options? : Opt) => {
      if (!loader) return null;

      const [result] = await load(
        async () => loader(input, options),
        async (result) => {
          dispatch(result);
          if (loaderCallback) await loaderCallback(result);
        },
        `${input}`,
      );
      return result;
    },
    [loader, loaderCallback, dispatch, load],
  );
}

interface useBulkLoaderProps<Out>{
  dispatch : (result : { [key : number] : Out | null } | null) => void;
  loader? :
    Transaction<(string | number)[], undefined, { [key : string] : Out }>;
  loaderCallback? : TransactionCallback<{ [key : string] : Out | null } | null>;
}

export function useBulkLoader<Out>({
  dispatch,
  loader,
  loaderCallback,
} : useBulkLoaderProps<Out>) {
  const { splitLoad } = useLoading<Out>();

  return useCallback(async (input : number[]) => {
    if (!loader) return {};
    const results = await splitLoad(
      input,
      loader,
      async (results) => {
        dispatch(results);
        if (loaderCallback) await loaderCallback(results);
      },
    );
    return results;
  }, [loader, loaderCallback, dispatch, splitLoad]);
}

interface useNullLoaderWithReturnProps<Options, Out, Returns> {
  dispatch : (result : Out | null) => void;
  loader? : NullAuxTransaction<Options, Out, Returns>;
  loaderCallback? : AuxTransactionCallback<Out | null, Returns>;
}

export function useNullLoaderWithReturn<Options, Out, Returns>({
  dispatch,
  loader,
  loaderCallback,
} : useNullLoaderWithReturnProps<Options, Out, Returns>) {
  const { load } = useLoading<[Out | null, Returns]>();

  return useCallback(
    async <Opt extends Options>(options? : Opt) => {
      if (!loader) return null;

      const [result] = await load(
        async () => loader(options),
        async (result) => {
          const [output, add] = result;
          dispatch(output);
          if (loaderCallback) await loaderCallback(output, add);
        }
      );
      return result;
    },
    [loader, loaderCallback, dispatch, load],
  );
}

interface useLoaderWithReturnProps<In, Options, Out, Returns> {
  dispatch : (result : Out | null, input? : In) => void;
  loader? : AuxTransaction<In, Options, Out, Returns>;
  loaderCallback? : AuxTransactionCallback<Out | null, Returns>;
}

export function useLoaderWithReturn<In, Options, Out, Returns>({
  dispatch,
  loader,
  loaderCallback,
} : useLoaderWithReturnProps<In, Options, Out, Returns>) {
  const { load } = useLoading();

  return useCallback(
    async <Opt extends Options>(input : In, options? : Opt) => {
      if (!loader) return null;

      const [result] = await load(
        async () => loader(input, options),
        async (result) => {
          const [output, add] = result;
          dispatch(output, input);
          if (loaderCallback) await loaderCallback(output, add);
        },
        `${input}`,
      );
      return result;
    },
    [loader, loaderCallback, dispatch, load],
  );
}

interface useRefreshProps<R, Options> {
  dispatch : React.Dispatch<DataAction<R>>;
  retrieve? : Transaction<number, Options, R>;
  callback? : TransactionCallback<R | null>;
}

export function useRefresh<R extends Record, Options = any>({
  dispatch,
  retrieve,
  callback,
} : useRefreshProps<R, Options>) {
  const dispatchRetrieved = useCallback(
    (record : R | null, input? : number) => dispatchAdd(
      dispatch,
      record,
      input,
    ),
    [dispatch],
  )

  return useLoader({
    dispatch : dispatchRetrieved,
    loader : retrieve,
    loaderCallback : callback,
  });
}

interface useRefreshWithReturnProps<R, Options, Returns> {
  dispatch : React.Dispatch<DataAction<R>>;
  retrieve? : AuxTransaction<number, Options, R, Returns>;
  callback? : AuxTransactionCallback<R | null, Returns>;
}

export function useRefreshWithReturn<R extends Record, Options, Returns>({
  dispatch,
  retrieve,
  callback,
} : useRefreshWithReturnProps<R, Options, Returns>) {
  const dispatchRetrieved = useCallback(
    (record : R | null, input? : number) => dispatchAdd(
      dispatch,
      record,
      input,
    ),
    [dispatch],
  )

  return useLoaderWithReturn<number, Options, R, Returns>({
    dispatch : dispatchRetrieved,
    loader : retrieve,
    loaderCallback : callback,
  });
}

interface useRefreshBulkProps<R, Options> {
  dispatch : React.Dispatch<DataAction<R>>;
  retrieve? : Transaction<number[], Options, DataIndex<R>>;
  callback? : TransactionCallback<DataIndex<R> | null>;
}

export function useRefreshBulk<R extends Record, Options>({
  dispatch,
  retrieve,
  callback,
} : useRefreshBulkProps<R, Options>) {
  const dispatchRetrieved = useCallback(
    (records : { [key : string] : R | null } | null) =>
      dispatchBulkAdd(dispatch, records),
    [dispatch],
  )

  const serializeKeys = useCallback(
    async (keys : (string | number)[]) => {
      return retrieve
        ? await retrieve(keys.map(key => typeof key === 'string'
          ? parseInt(key)
          : key
        ))
        : null},
    [retrieve],
  );

  return useBulkLoader<R | null>({
    dispatch : dispatchRetrieved,
    loader : serializeKeys,
    loaderCallback : callback,
  });
}

interface useRefreshIndexProps<R, Options> {
  dispatch : React.Dispatch<DataAction<R>>;
  timestamp? : Date;
  retrieve? : NullTransaction<Options, DataIndex<R>>;
  callback? : TransactionCallback<DataIndex<R> | null>;
}

export function useRefreshIndex<
  R extends Record,
  Options extends RefreshOptions = any
>({
  timestamp,
  dispatch,
  retrieve,
  callback,
} : useRefreshIndexProps<R, Options>) {
  const dispatchRetrieved = useCallback(
    (records : DataIndex<R> | null) => dispatchSet(dispatch, records),
    [dispatch],
  )

  const retreiveFromTime = useCallback(
    async (options? : Options) => {
      const opt = { since : timestamp, ...(options as Options) };
      return retrieve ? await retrieve(opt) : null;
    },
    [timestamp, retrieve],
  );

  return useNullLoader({
    dispatch : dispatchRetrieved,
    loader : retreiveFromTime,
    loaderCallback : callback,
  });
}

interface useRefreshIndexWithReturnProps<R, Options, Returns> {
  dispatch : React.Dispatch<DataAction<R>>;
  timestamp? : Date;
  retrieve? : NullAuxTransaction<Options, DataIndex<R>, Returns>;
  callback? : AuxTransactionCallback<DataIndex<R> | null, Returns>;
}

export function useRefreshIndexWithReturn<R extends Record, Options, Returns>({
  timestamp,
  dispatch,
  retrieve,
  callback,
} : useRefreshIndexWithReturnProps<R, Options, Returns>) {
  const dispatchRetrieved = useCallback(
    (records : DataIndex<R> | null) => dispatchSet(dispatch, records),
    [dispatch],
  )

  const retreiveFromTime = useCallback(
    async (options? : Options) => {
      const opt = { since : timestamp, ...(options as Options) };
      return retrieve ? await retrieve(opt) : [null, null] as [null, Returns];
    },
    [timestamp, retrieve],
  );

  return useNullLoaderWithReturn<Options, DataIndex<R>, Returns>({
    dispatch : dispatchRetrieved,
    loader : retreiveFromTime,
    loaderCallback : callback,
  });
}

type RefreshOptions = { since? : Date } | undefined;
type RetrieveOptions = RefreshOptions & { check? : boolean };

// DEPR: timestamp and maxAge
interface useRetrieveProps<R, Options extends RefreshOptions> {
  data : DataIndex<R> | null;
  refresh : Transaction<number, Options, R>;
  /** @deprecated */
  timestamp? : Date;
  /** @deprecated */
  maxAge? : number;
  validate? : (record : R | null, options? : Options) => boolean;
}

export function useRetrieve<
  R extends Record,
  Options extends RefreshOptions = {},
>({
  data,
  refresh,
  validate,
} : useRetrieveProps<R, Options>) {
  return useCallback(
    async (id: number, options?: Options) => {
      const existing = data?.[id];
      const valid = (existing !== undefined) && (validate
        ? validate(existing, options)
        : true
      );

      if (!valid) {
        const opt = { ...(options as Options) };
        return await refresh(id, opt);
      }

      return existing;
    },
    [data, validate, refresh],
  );
}

// DEPR: timestamp and maxAge
interface useRetrieveWithReturnProps<R, Options extends RefreshOptions, Returns> {
  data : DataIndex<R> | null;
  refresh : AuxTransaction<number, Options, R, Returns>;
  getReturns : (record : R | null) => Returns;
  /** @deprecated */
  timestamp? : Date;
  /** @deprecated */
  maxAge? : number;
  validate? : (record : R | null, options? : Options) => boolean;
}

export function useRetrieveWithReturn<
  R extends Record,
  Options extends RefreshOptions,
  Returns,
>({
  data,
  refresh,
  getReturns,
  validate
} : useRetrieveWithReturnProps<R, Options, Returns>) {
  return useCallback(
    async (id : number, options? : Options) => {
      const existing = data?.[id];
      const valid = (existing !== undefined) && (validate
        ? validate(existing, options)
        : true
      );

      if (!valid) {
        const opt = { ...(options as Options) };
        return await refresh(id, opt);
      }
      return [existing, getReturns(existing)] as [R, Returns];
    },
    [data, refresh, getReturns, validate],
  );
}

interface useRetrieveBulkProps<R> {
  data : DataIndex<R> | null;
  refresh : Transaction<number[], undefined, DataIndex<R>>;
  validate? : (record : R | null) => boolean;
}

export function useRetrieveBulk<R extends Record>({
  data,
  refresh,
  validate,
} : useRetrieveBulkProps<R>) {
  return useCallback(
    async (ids : number[]) => {
      const invalid = ids.filter(
        i => (data?.[i] === undefined || (validate && !validate(data?.[i])))
      );
      const existing = ids.reduce((acc, id) => {
        if (data?.[id] !== undefined) {
          acc[id] = data[id];
        }
        return acc;
      }, {} as DataIndex<R>);

      if (invalid.length > 0) {
        return {
          ...existing,
          ...await refresh(invalid)
        };
      }
      return existing;
    },
    [data, refresh, validate],
  );
}

interface useRetrieveIndexProps<R> {
  data : DataIndex<R> | null;
  refresh : NullTransaction<RefreshOptions, DataIndex<R>>;
  timestamp? : Date;
  maxAge? : number;
  validate? : (records : DataIndex<R>, options? : RefreshOptions) => boolean;
}

export function useRetrieveIndex<R extends Record>({
  data,
  timestamp,
  maxAge,
  refresh,
} : useRetrieveIndexProps<R>) {
  return useCallback(
    async (options? : RetrieveOptions) => {
      if (
        data === null ||
        options?.check ||
        (maxAge !== undefined &&
          (timestamp ? (Date.now() - timestamp?.getTime()) : 0) > maxAge)
      ) {
        const opt = { ...options, since : timestamp };
        return { ...data, ...await refresh(opt) };
      }
      return data;
    },
    [data, timestamp, maxAge, refresh],
  );
}

interface useRetrieveIndexWithReturnProps<R, Returns> {
  data : DataIndex<R> | null;
  refresh : NullAuxTransaction<RefreshOptions, DataIndex<R>, Returns>;
  getReturns : (records : DataIndex<R>) => Returns;
  timestamp? : Date;
  maxAge? : number;
}

export function useRetrieveIndexWithReturn<R extends Record, Returns>({
  data,
  timestamp,
  maxAge,
  refresh,
  getReturns,
} : useRetrieveIndexWithReturnProps<R, Returns>) {
  return useCallback(
    async (options? : RetrieveOptions) => {
      if (
        data === null ||
        options?.check ||
        (maxAge !== undefined &&
          (timestamp ? (Date.now() - timestamp?.getTime()) : 0) > maxAge)
      ) {
        const opt = { ...options, since : timestamp };
        return { ...data, ...await refresh(opt) };
      }
      return [data, getReturns(data)] as [DataIndex<R>, Returns];
    },
    [data, timestamp, maxAge, refresh, getReturns],
  );
}

interface useChangeProps<A, Options, B, C> {
  dispatch : React.Dispatch<DataAction<C>>;
  change? : Transaction<A, Options, B>;
  callback? : TransactionCallback<B>;
}

export function useChange<
  A extends Record,
  B extends Record,
  Options = any,
>(props : useChangeProps<A, Options, B, B>) : (<Opt extends Options>(
  input : A,
  options? : Opt
) => Promise<B | null>);
export function useChange<
  A extends Record,
  B extends boolean,
  Options = any,
>(props : useChangeProps<A, Options, B, boolean>) : (<Opt extends Options>(
  input : A,
  options? : Opt
) => Promise<boolean | null>);
export function useChange<
  A extends Record,
  B extends Record | boolean,
  Options,
>({
  dispatch,
  change,
  callback,
} : useChangeProps<A, Options, B, A | B & Record>) {
  const dispatchChange = useCallback(
    (result : B, input? : A) => typeof result === 'boolean'
      ? (result ? dispatchAdd(dispatch, input ?? null) : null)
      : dispatchAdd(dispatch, result),
    [dispatch],
  )

  return useTransaction({
    dispatch : dispatchChange,
    transaction : change,
    transactionCallback : callback,
  });
}

interface useBulkChangeProps<A, Options, B> {
  dispatch : React.Dispatch<DataAction<B>>;
  change? : Transaction<A[], Options, DataIndex<B>>;
  callback? : TransactionCallback<DataIndex<B>>;
}

export function useBulkChange<A extends Record, B extends Record, Options>({
  dispatch,
  change,
  callback,
} : useBulkChangeProps<A, Options, B>) {
  const dispatchChange = useCallback(
    (records : DataIndex<B>) => dispatchBulkAdd(dispatch, records),
    [dispatch],
  )

  return useTransaction({
    dispatch : dispatchChange,
    transaction : change,
    transactionCallback : callback,
  });
}

interface useChangeWithReturnProps<A, Options, B, Returns> {
  dispatch : React.Dispatch<DataAction<B>>;
  change? : AuxTransaction<A, Options, B, Returns>
  callback? : AuxTransactionCallback<B, Returns>
}

export function useChangeWithReturn<
  A extends Record,
  B extends Record,
  Opt,
  Ret,
>({
  dispatch,
  change,
  callback,
} : useChangeWithReturnProps<A, Opt, B, Ret>) {
  const dispatchChange = useCallback(
    (record : B) => dispatchAdd(dispatch, record),
    [dispatch],
  )

  return useTransactionWithReturn<A, Opt, B, Ret>({
    dispatch : dispatchChange,
    transaction : change,
    transactionCallback : callback,
  });
}

interface useBulkChangeWithReturnProps<A, Options, B, Returns> {
  dispatch : React.Dispatch<DataAction<B>>;
  change? : AuxTransaction<A, Options, DataIndex<B>, Returns>
  callback? : AuxTransactionCallback<DataIndex<B>, Returns>
}

export function useBulkChangeWithReturn<
  A,
  B extends Record,
  Opt,
  Ret,
>({
  dispatch,
  change,
  callback,
} : useBulkChangeWithReturnProps<A, Opt, B, Ret>) {
  const dispatchCreated = useCallback(
    (records : DataIndex<B>) => dispatchBulkAdd(dispatch, records),
    [dispatch],
  )

  return useTransactionWithReturn<A, Opt, DataIndex<B>, Ret>({
    dispatch : dispatchCreated,
    transaction : change,
    transactionCallback : callback,
  });
}

interface useDeleteProps<A, Options, B, Out = B | boolean> {
  dispatch : React.Dispatch<DataAction<B>>;
  delete? : Transaction<A, Options, Out>;
  callback? : TransactionCallback<Out>;
}

export function useDelete<
  A extends Record,
  B extends Record,
  Options = any,
>(props : useDeleteProps<A, Options, B, B>) : (<Opt extends Options>(
  input : A,
  options? : Opt
) => Promise<B | null>);
export function useDelete<
  A extends Record,
  B extends Record,
  Options = any,
>(props : useDeleteProps<A, Options, B, boolean>) : (<Opt extends Options>(
  input : A,
  options? : Opt
) => Promise<boolean | null>);
export function useDelete<
  A extends Record,
  B extends Record | boolean,
  Options,
>({
  dispatch,
  delete : del,
  callback,
} : useDeleteProps<A, Options, A | B & Record, B>) {
  const dispatchDeleted = useCallback(
    (result : B, input? : A) => typeof result === 'boolean'
      ? (result ? dispatchRemove(dispatch, input ?? null) : null)
      : dispatchRemove(dispatch, result),
    [dispatch],
  )

  return useTransaction({
    dispatch : dispatchDeleted,
    transaction : del,
    transactionCallback : callback,
  });
}

interface useDeleteWithReturnProps<A, Options, B, Returns> {
  dispatch : React.Dispatch<DataAction<B>>;
  delete? : AuxTransaction<A, Options, B, Returns>;
  callback? : AuxTransactionCallback<B, Returns>;
}

export function useDeleteWithReturn<
  A extends Record,
  B extends Record,
  Options,
  Returns,
>({
  dispatch,
  delete : del,
  callback,
} : useDeleteWithReturnProps<A, Options, B, Returns>) {
  const dispatchDeleted = useCallback(
    (record : B) => dispatchRemove(dispatch, record),
    [dispatch],
  )

  return useTransactionWithReturn<A, Options, B, Returns>({
    dispatch : dispatchDeleted,
    transaction : del,
    transactionCallback : callback,
  });
}

interface useBulkDeleteProps<A, Options, B> {
  dispatch : React.Dispatch<DataAction<B>>;
  delete? : Transaction<A[], Options, DataIndex<B>>;
  callback? : TransactionCallback<DataIndex<B>>;
}

export function useBulkDelete<A extends Record, B extends Record, Options>({
  dispatch,
  delete : del,
  callback,
} : useBulkDeleteProps<A, Options, B>) {
  const dispatchDeleted = useCallback(
    (records : DataIndex<B>) => dispatchBulkRemove(dispatch, records),
    [dispatch],
  )

  return useTransaction({
    dispatch : dispatchDeleted,
    transaction : del,
    transactionCallback : callback,
  });
}

interface useBulkDeleteWithReturnProps<A, Options, B, Returns> {
  dispatch : React.Dispatch<DataAction<B>>;
  delete? : AuxTransaction<A[], Options, DataIndex<B>, Returns>;
  callback? : AuxTransactionCallback<DataIndex<B>, Returns>;
}

export function useBulkDeleteWithReturn<
  A extends Record,
  B extends Record,
  Options,
  Returns,
>({
  dispatch,
  delete : del,
  callback,
} : useBulkDeleteWithReturnProps<A, Options, B, Returns>) {
  const dispatchDeleted = useCallback(
    (records : DataIndex<B>) => dispatchBulkRemove(dispatch, records),
    [dispatch],
  )

  return useTransactionWithReturn<A[], Options, DataIndex<B>, Returns>({
    dispatch : dispatchDeleted,
    transaction : del,
    transactionCallback : callback,
  });
}

interface useRelateProps<A, B, Options, Out, C> {
  dispatch : React.Dispatch<DataAction<C>>;
  relate? : DualTransaction<A, B, Options, Out>;
  callback? : TransactionCallback<Out>;
}

export function useRelate<
  A extends Record,
  B,
  C extends Record,
  Options = any,
>(props : useRelateProps<A, B, Options, C, C>) : (<Opt extends Options>(
  inputA : A,
  inputB : B,
  options? : Opt
) => Promise<C | null>);
export function useRelate<
  A extends Record,
  B,
  C extends Record,
  Options = any,
>(props : useRelateProps<A, B, Options, boolean, C>) : (<Opt extends Options>(
  inputA : A,
  inputB : B,
  options? : Opt
) => Promise<boolean | null>);
export function useRelate<
  A extends Record,
  B,
  C extends Record | boolean,
  Options = any,
>({
  dispatch,
  relate: createRelated,
  callback,
} : useRelateProps<A, B, Options, C, A | C & Record>) {
  const dispatchCreated = useCallback(
    (record : C, input? : A) => typeof record === 'boolean'
      ? (record ? dispatchAdd(dispatch, input ?? null) : null)
      : dispatchAdd(dispatch, record),
    [dispatch],
  )

  return useDualTransaction({
    dispatch : dispatchCreated,
    transaction : createRelated,
    transactionCallback : callback,
  });
}

interface useRelateWithReturnProps<A, B, Options, Returns> {
  dispatch : React.Dispatch<DataAction<A>>;
  createRelated? : DualAuxTransaction<A, B, Options, A, Returns>;
  callback? : AuxTransactionCallback<A, Returns>;
}

export function useRelateWithReturn<
  A extends Record,
  B extends Record,
  Options = any,
  Returns = any,
>({
  dispatch,
  createRelated,
  callback,
} : useRelateWithReturnProps<A, B, Options, Returns>) {
  const dispatchCreated = useCallback(
    (record : A) => dispatchAdd(dispatch, record),
    [dispatch],
  )

  return useDualTransactionWithReturn<A, B, Options, A, Returns>({
    dispatch : dispatchCreated,
    transaction : createRelated,
    transactionCallback : callback,
  });
}

interface useLoadProps {
  data : DataIndex<any> | null;
  loader : () => void;
  timeout? : number;
  checkLoaded? : () => boolean;
}

export function useLoad({ data, loader, timeout, checkLoaded } : useLoadProps) {
  const loaded = useRef(false);

  const load = useCallback(
    async () => {
      if (loaded.current) return;
      loaded.current = true;

      loader();
      if (timeout) setTimeout(() => loaded.current = false, timeout);
    },
    [timeout, loader],
  );

  return {
    loaded : checkLoaded ? checkLoaded() : (data !== null),
    load,
  }
}

interface useDataProps<RT>{
  initCache? : DataIndex<RT>;
  storageKey? : string;
  useDB? : boolean;
  serializer? : (data : RT) => any;
  deserializer? : (data : any) => RT | null;
}

function useData<RT extends Record>({
  initCache,
  storageKey,
  useDB = false,
  serializer,
  deserializer,
} : useDataProps<RT> = {}) {
  const {
    data : storeData,
    lastUpdated : storeLastUpdated,
    dispatch : storeDispatch,
    clearCache : storeClearCache,
  } = useStorage<RT>({
    initData : initCache,
    storageKey : (useDB ? undefined : storageKey),
    serializer,
    deserializer,
  });

  const {
    data : dbData,
    lastUpdated : dbLastUpdated,
    dispatch : dbDispatch,
    clearCache : dbClearCache,
  } = useIndexedDB<RT>({
    initData : initCache,
    storageKey : (useDB ? storageKey : undefined),
    serializer,
    deserializer,
  });

  return {
    data : useDB ? dbData : storeData,
    dispatch : useDB ? dbDispatch : storeDispatch,
    lastUpdated : useDB ? dbLastUpdated : storeLastUpdated,
    clearCache : useDB ? dbClearCache : storeClearCache,
  };
}

export default useData;
