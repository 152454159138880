import * as Sentry from '@sentry/react'

import packageJson from '../package.json'

function isVersionNew(current : string, last : string) {
  if (!current || !last) return false

  const [releaseA, prereleaseA] = current.split(/-/)
  const [releaseB, prereleaseB] = last.split(/-/)

  const relA = releaseA.split(/\./g)
  const relB = releaseB.split(/\./g)

  while (relA.length || relB.length) {
    const a = Number(relA.shift())
    const b = Number(relB.shift())
    if (a === b) continue
    return a > b || isNaN(b)
  }

  if (prereleaseA === undefined) return (prereleaseB !== undefined)
  if (prereleaseB === undefined) return false

  const preA = prereleaseA.split(/\./g)
  const preB = prereleaseB.split(/\./g)

  while (preA.length || preB.length) {
    const a = preA.shift()
    const b = preB.shift()

    if (a === undefined) return false
    if (b === undefined) return true

    if (isNaN(Number(a))) {
      if (a === b) continue
      return a > b || !isNaN(Number(b))
    }
    if (isNaN(Number(b))) return false

    if (Number(a) === Number(b)) continue
    return Number(a) > Number(b)
  }

  return false
}

export function getRelease() {
  return `${packageJson.name}@${packageJson.version}`
}

export function checkCache() {
  fetch('/meta.json', { cache: 'no-store' })
    .then((response) => response.json())
    .then((meta) => {
      const latest = meta.version
      const current = localStorage.getItem('appVersion');
      if (current && isVersionNew(latest, current)) {
        const auth = localStorage.getItem('auth');

        localStorage.clear();
        if (window.indexedDB) {
          window.indexedDB.databases().then((dbs) => dbs.forEach(
            (db) => db.name && window.indexedDB.deleteDatabase(db.name)
          ))
        }
        if (auth) localStorage.setItem('auth', auth);
        localStorage.setItem('appVersion', latest)
        window.location.reload();
      } else if (!current) {
        localStorage.setItem('appVersion', latest)
      }
    })
    .catch((err) => Sentry.captureException(err))
}
