import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  DataIndex,
  Currency,
  ServiceChannel,
  Tax,
  LineItem,
  Selection,
  Subscription,
  SubscriptionOption,
  Fulfilment,
  Order,
  Subtotal,
  DraftOrder,
  ProjectedOrder,
} from '#mrktbox/clerk/types';

import OrderContext from '#mrktbox/clerk/context/OrderContext';
import OptionsContext from '#mrktbox/clerk/context/OptionsContext';

import useData, { useLoad } from '#mrktbox/clerk/hooks/useData';
import useCache from '#mrktbox/clerk/hooks/useDataCache';
import useAddresses from '#mrktbox/clerk/hooks/useAddresses';
import useScheduling from '#mrktbox/clerk/hooks/useScheduling';
import useTaxes from '#mrktbox/clerk/hooks/useTaxes';
import useAdjustments from '#mrktbox/clerk/hooks/useAdjustments';
import useOrders from '#mrktbox/clerk/hooks/useOrders';
import useSubcriptionsAPI from '#mrktbox/clerk/hooks/api/useSubscriptionsAPI';

import { addCurrency, multiplyCurrency } from '#mrktbox/clerk/utils/currency';
import { listRecords } from '#mrktbox/clerk/utils/data';

export type SubscriptionIndex = DataIndex<Subscription>;

type FulfilmentReturn = {
  fulfilments : DataIndex<Fulfilment>;
  orders : DataIndex<Order>;
  selections : DataIndex<Selection>;
};

const MAX_AGE = 1000 * 60 * 60;

const SubscriptionsContext = createContext({
  subscriptions : {} as SubscriptionIndex | null,
  susbcriptionOptions : {} as DataIndex<SubscriptionOption> | null,
  projectedOrders : [] as ProjectedOrder[],
  loaded : false,
  load : () => {},
  createSubscription : async (
    subscription : Subscription,
    selections? : Selection[],
    lineItem? : LineItem,
  ) => null as {
    subscription : Subscription,
    lineItem : LineItem,
    selections : DataIndex<Selection>,
    orders : DataIndex<Order>,
  } | null,
  reloadSubscriptions : async () => null as SubscriptionIndex | null,
  refreshSubscriptions : async () => null as SubscriptionIndex | null,
  refreshSubscription : async (id : number) => null as Subscription | null,
  retrieveSubscriptions : async () => null as SubscriptionIndex | null,
  retrieveSubscription : async (id : number) => null as Subscription | null,
  bulkCreateSubscriptions : async ({
    subscriptions,
    selections,
    lineItems,
  } : {
    subscriptions : Subscription[],
    selections? : Selection[],
    lineItems? : LineItem[],
  }) => null as {
    subscriptions : DataIndex<Subscription>,
    selections : DataIndex<Selection>,
    lineItems : DataIndex<LineItem>,
  } | null,
  evaluateSubscriptions : (
    subscriptions : Subscription[],
    iteration : number,
  ) => null as Subscription | null,
  projectOrders : async (
    projectFrom? : Date,
    projectTo? : Date,
  ) => [] as ProjectedOrder[],
  retrieveSubscriptionOptions :
    async () => null as DataIndex<SubscriptionOption> | null,
  addServiceChannelToSubscriptionOption : async ({
    option,
    serviceChannel,
  } : {
    option : SubscriptionOption,
    serviceChannel : ServiceChannel,
  }) => null as SubscriptionOption | null,
  removeServiceChannelFromSubscriptionOption : async ({
    option,
    serviceChannel,
  } : {
    option : SubscriptionOption,
    serviceChannel : ServiceChannel,
  }) => null as SubscriptionOption | null,
  bulkCreateFulfilments : async ({
    lineItems,
    selections,
    subscriptions,
    targetIteration,
    defer,
  } : {
    lineItems? : LineItem[],
    selections? : Selection[],
    subscriptions? : Subscription[],
    targetIteration? : number,
    defer? : boolean,
  }) => null as DataIndex<Order> | null,
});

interface SubscriptionsProviderProps {
  children : React.ReactNode;
}

export function SubscriptionsProvider({
  children
} : SubscriptionsProviderProps) {
  const { cacheSelections } = useContext(OptionsContext);
  const {
    lineItems,
    orders,
    orderAddresses,
    cacheLineItems,
    cacheOrders,
    dispatchOrderAddresses,
    createOrderFromLineItem,
    buildOrphanedOrders,
    validateOrder,
  } = useContext(OrderContext);
  const { selections, calculateLinePrice } = useContext(OptionsContext);

  const { retrieveAddressesBulk } = useAddresses();
  const { timeSlots, calculateTime, findNextDivision } = useScheduling();
  const { taxes } = useTaxes();
  const { getServiceAdjustments } = useAdjustments();
  const { determineService } = useOrders();
  const {
    createSubscription,
    retrieveSubscriptions,
    retrieveSubscription,
    bulkCreateSubscriptions,
    retrieveSubscriptionOptions,
    addServiceChannelToSubscriptionOption,
    removeServiceChannelFromSubscriptionOption,
    bulkCreateFulfilments,
  } = useSubcriptionsAPI();

  const [projectedOrders, setProjectedOrders] = useState<ProjectedOrder[]>([]);

  const {
    data : subscriptions,
    dispatch : dispatchSubscriptions,
    lastUpdated : subscriptionsLastUpdated,
  } = useData<Subscription>({ storageKey : 'subscriptions', useDB : true });

  const {
    data : options,
    dispatch : dispatchOptions,
    lastUpdated : optionsLastUpdated,
  } = useData<SubscriptionOption>({ storageKey : 'subscriptionOptions' });

  const subscriptionsStale = subscriptionsLastUpdated !== undefined &&
    (new Date().getTime() - subscriptionsLastUpdated.getTime()) > MAX_AGE;
  const optionsStale = optionsLastUpdated !== undefined &&
    (new Date().getTime() - optionsLastUpdated.getTime()) > MAX_AGE;

  const parseSubscription = useCallback(({
    subscription,
    selections,
    lineItem,
    orders,
  } : {
    subscription : Subscription,
    selections : DataIndex<Selection>,
    lineItem : LineItem,
    orders : DataIndex<Order>,
  }) => {
    if (lineItem.id) cacheLineItems({ [lineItem.id] : lineItem });
    cacheSelections(selections ?? {});
    cacheOrders(orders ?? {});
    return subscription.id
      ? { [subscription.id] : subscription }
      : {};
  }, [cacheLineItems, cacheSelections, cacheOrders]);

  const parseSubscriptions = useCallback(({
    subscriptions,
    selections,
    lineItems,
    orders,
  } : {
    subscriptions : DataIndex<Subscription>,
    selections : DataIndex<Selection>,
    lineItems : DataIndex<LineItem>,
    orders : DataIndex<Order>,
  }) => {
    cacheLineItems(lineItems ?? {});
    cacheSelections(selections ?? {});
    cacheOrders(orders ?? {});
    return subscriptions ?? {};
  }, [cacheLineItems, cacheSelections, cacheOrders]);

  const parseFulfilments = useCallback((response : FulfilmentReturn) => {
    cacheSelections(response.selections);
    return response.orders;
  }, [cacheSelections]);

  const dispatchOrders = useCallback(({ data, type } : {
    data : DataIndex<Order> | null,
    type? : string,
  }) => {
    cacheOrders(data);
    return data;
  }, [cacheOrders]);

  const queryRecentSubscriptions = useCallback(async () => {
    return retrieveSubscriptions({ since : subscriptionsLastUpdated });
  }, [retrieveSubscriptions, subscriptionsLastUpdated]);

  const newSubscription = useCache({
    process : createSubscription,
    parser : parseSubscription,
    dispatch : dispatchSubscriptions,
  })
  const reloadSubsctiptions = useCache({
    process : retrieveSubscriptions,
    dispatch : dispatchSubscriptions,
    refresh : true,
    isLoader : true,
  });
  const refreshSubscriptions = useCache({
    process : queryRecentSubscriptions,
    dispatch : dispatchSubscriptions,
    update : true,
    isLoader : true,
  });
  const refreshSubscription = useCache({
    process : retrieveSubscription,
    dispatch : dispatchSubscriptions,
    isLoader : true,
  });
  const getSubscriptions = useCache({
    process : retrieveSubscriptions,
    dispatch : dispatchSubscriptions,
    data : subscriptions,
    stale : subscriptionsStale,
    refresh : true,
    isLoader : true,
  });
  const getSubscription = useCache({
    process : retrieveSubscription,
    dispatch : dispatchSubscriptions,
    data : subscriptions,
    stale : subscriptionsStale,
    isLoader : true,
  });

  const bulkNewSubscriptions = useCache({
    process : bulkCreateSubscriptions,
    parser : parseSubscriptions,
    dispatch : dispatchSubscriptions,
  });

  const bulkNewFulfilments = useCache({
    process : bulkCreateFulfilments,
    parser : parseFulfilments,
    filter : parseFulfilments,
    dispatch : dispatchOrders,
  });

  const refreshOptions = useCache({
    process : retrieveSubscriptionOptions,
    dispatch : dispatchOptions,
    refresh : true,
    isLoader : true,
  });
  const getOptions = useCache({
    process : retrieveSubscriptionOptions,
    dispatch : dispatchOptions,
    data : options,
    stale : optionsStale,
    refresh : true,
    isLoader : true,
  });

  const addOptionServiceChannel = useCache({
    process : addServiceChannelToSubscriptionOption,
    dispatch : dispatchOptions,
  });
  const removeOptionServiceChannel = useCache({
    process : removeServiceChannelFromSubscriptionOption,
    dispatch : dispatchOptions,
  });

  const { load : loadSubscriptions, loaded : subscriptionLoaded } = useLoad({
    data : subscriptions,
    loader : refreshSubscriptions,
  });
  const { load : loadOptions, loaded : optionsLoaded } = useLoad({
    data : options,
    loader : refreshOptions,
  });

  const load = useCallback(() => {
    if (!subscriptions) return;
    loadSubscriptions();
    loadOptions();
  }, [
    subscriptions,
    loadSubscriptions,
    loadOptions,
  ]);

  const evaluateSubscriptions = useCallback((
    subscriptions : Subscription[],
    iteration : number,
  ) => {
    const applicableSubscriptions = subscriptions.filter(sub => {
      return (
        sub.startIteration <= iteration
          && ((sub.endIteration === null) || (sub.endIteration >= iteration))
      );
    });
    if (applicableSubscriptions.length === 0) return null;

    return applicableSubscriptions.reduce((last, sub) => {
      if (sub.id && sub.id > (last.id ?? 0)) return sub;
      return last;
    }, applicableSubscriptions[0]);
  }, []);

  const matchLineItemOrder = useCallback((
    lineItem : LineItem,
    order : DraftOrder,
  ) => {
    return (
      ((order.serviceChannel === null && lineItem.serviceChannelId === null)
        || order.serviceChannel?.id === lineItem.serviceChannelId)
      && ((order.address === null && lineItem.addressId === null)
        || order.address?.id === lineItem.addressId)
      && ((order.location === null && lineItem.locationId === null)
        || order.location?.id === lineItem.locationId)
      && ((order.timeSlot === null && lineItem.timeSlotId === null)
        || order.timeSlot?.id === lineItem.timeSlotId)
      && ((
        order.customer === null
          && lineItem.customerId === null
          && (
            (order.guestCode === null && lineItem.guestCode === null)
             || order.guestCode === lineItem.guestCode
      )) || order.customer?.id === lineItem.customerId)
      && (order.timeSlotIteration === lineItem.timeSlotIteration)
      && (order.timeSlotDivision === lineItem.timeSlotDivision)
    );
  }, []);

  const matchSubscriptionOrder = useCallback((
    subscription : Subscription,
    order : ProjectedOrder,
    iteration : number,
  ) => {
    const effectiveIteration = iteration +
      (subscription.targetIteration - subscription.startIteration);
    return (
      ((order.serviceChannel?.id ?? null) === subscription.serviceChannelId) &&
      ((order.address?.id ?? null) === subscription.addressId) &&
      ((order.location?.id ?? null) === subscription.locationId) &&
      ((order.timeSlot?.id ?? null) === subscription.timeSlotId) &&
      (order.timeSlotIteration === effectiveIteration) &&
      (order.timeSlotDivision === subscription.timeSlotDivision)
    );
  }, []);

  const customiseProjectedOrder = useCallback((
    order : ProjectedOrder,
  ) : ProjectedOrder => {
    const allSelections = listRecords(selections);
    const allSubscriptions = listRecords(subscriptions);

    const itemSelections = Object.values(order.lineItems).filter(
      (lineItem) => !Object.values(order.subscriptions).some(
        (sub) => sub.lineItemId === lineItem.id,
      ),
    ).reduce((select, lineItem) => {
      allSelections.filter(s => (
        lineItem.id === s.lineItemId
          && !allSubscriptions.some(
            sub => s.id && sub.selectionIds.includes(s.id)
          )
          && !Object.values(order.subscriptions).some(
            sub => sub.lineItemId === lineItem.id
          )
      )).reduce((sel, s) => {
        if (s.id) sel[s.id] = s;
        return sel;
      }, select);
      return select;
    }, {} as { [id : number] : Selection });

    const subscriptionSelections = allSelections.filter(
      select => Object.values(order.subscriptions).some(
        sub => select.id && sub.selectionIds.includes(select.id)
      )
    ).reduce((select, s) => {
      if (s.id) select[s.id] = s;
      return select;
    }, {} as { [id : number] : Selection });

    return {
      ...order,
      selections : {
        ...itemSelections,
        ...subscriptionSelections,
      },
    };
  }, [selections, subscriptions]);

  const createProjectedOrderFromLineItem = useCallback(async (
    lineItem : LineItem,
  ) : Promise<ProjectedOrder | null> => {
    const order = await createOrderFromLineItem(lineItem);
    if (!order) return null;

    return {
      ...order,
      selections : {},
      subscriptions : {},
    };
  }, [createOrderFromLineItem]);

  const createProjectedOrderFromSubscription = useCallback(async (
    lineItem : LineItem,
    subscription : Subscription,
    iteration : number,
  ) : Promise<ProjectedOrder | null> => {
    const dummyItem : LineItem = {
      ...lineItem,
      id : NaN,
      serviceChannelId : subscription.serviceChannelId,
      locationId : subscription.locationId,
      addressId : subscription.addressId,
      timeSlotId : subscription.timeSlotId,
      timeSlotIteration : iteration +
        (subscription.targetIteration - subscription.startIteration),
      timeSlotDivision : subscription.timeSlotDivision,
    };

    const order = await createProjectedOrderFromLineItem(dummyItem);
    return order ? {
      ...order,
      lineItems : { [lineItem.id ?? 0] : lineItem },
      subscriptions : { [subscription.id ?? 0] : subscription },
    } : null;
  }, [createProjectedOrderFromLineItem]);

  const findIterationsInRange = useCallback((
    lineItem : LineItem,
    subscriptions : Subscription[],
    from : Date,
    to : Date,
  ) : [number | undefined, number | undefined, Subscription[]] => {
    const timeSlot = lineItem.timeSlotId
      ? timeSlots?.[lineItem.timeSlotId]
      : null;
    if (!timeSlot) return [undefined, undefined, []];

    const itemSubscriptions = Object.values(subscriptions ?? {}).filter(
      subscription => subscription && subscription?.lineItemId === lineItem.id
    ) as Subscription[];
    const applicableSubscriptions = itemSubscriptions.filter(
      subscription => {
        const timeSlot = timeSlots?.[subscription.timeSlotId];
        // TODO: warn if time slot not loaded
        if (!timeSlot) return false;

        const start = calculateTime(
          timeSlot,
          subscription.targetIteration,
          subscription.timeSlotDivision
        );
        const end = subscription.endIteration !== null
          ? calculateTime(
            timeSlot,
            subscription.targetIteration +
              (subscription.endIteration - subscription.startIteration),
            subscription.timeSlotDivision
          )
          : null;
        return start <= to && (!end || end >= from);
      }
    );

    if (applicableSubscriptions.length === 0) {
      const exact = calculateTime(
        timeSlot,
        lineItem.timeSlotIteration,
        lineItem.timeSlotDivision,
      )
      if (exact >= from && exact <= to) {
        return [lineItem.timeSlotIteration, lineItem.timeSlotIteration, []];
      } else {
        return [undefined, undefined, []];
      }
    }

    const firstIter = findNextDivision(timeSlot, from)?.iteration;
    const lastIter = findNextDivision(timeSlot, to)?.iteration;
    if (firstIter === undefined || lastIter === undefined) {
      return [undefined, undefined, []]
    };

    function findNext(subscription : Subscription, after : Date) {
      const timeSlot = timeSlots?.[subscription.timeSlotId];
      // TODO: warn if time slot not loaded
      if (!timeSlot) return undefined;

      const i = findNextDivision(timeSlot, after)?.iteration;
      if (i === undefined) return undefined;
      if (i < 0) return undefined;
      if (i < subscription.targetIteration) return undefined;
      if ((subscription.endIteration !== null)
        && i > subscription.targetIteration +
          (subscription.endIteration - subscription.startIteration)
      ) return undefined;
      return i - (subscription.targetIteration - subscription.startIteration);
    }

    const minIteration = applicableSubscriptions.reduce(
      (min, subscription) => Math.min(min, findNext(subscription, from) ?? min),
      firstIter,
    );
    const maxIteration = applicableSubscriptions.reduce(
      (max, subscription) => Math.max(
        max,
        (findNext(subscription, to) ?? max),
      ),
      lastIter - 1,
    );
    return [minIteration, maxIteration, applicableSubscriptions];
  }, [
    timeSlots,
    findNextDivision,
    calculateTime,
  ]);

  const calculateTax = useCallback((
    amount : Currency,
    tax : Tax,
  ) : Currency => {
    return {
      amount : Math.round(amount.amount * tax.rate),
      increment : amount.increment,
      currencyCode : amount.currencyCode,
      calculatedValue : Math.round(amount.amount * tax.rate) * amount.increment,
    };
  }, []);

  const updateTotals = useCallback((
    totals : Subtotal[],
    linePrice : Currency,
    applicableTaxes : Tax[],
  ) : Subtotal[] => {
    const newTotals = totals.map(t => ({ ...t, total : { ...t.total } }));

    applicableTaxes.forEach(tax => {
      const taxId = tax.id;
      if (!taxId) return;

      const existingTaxLine = newTotals.find(t => t.taxId === taxId);

      if (existingTaxLine) {
        const additionalTax = calculateTax(linePrice, tax);
        existingTaxLine.total.amount += additionalTax.amount;
        existingTaxLine.total.calculatedValue += additionalTax.calculatedValue;
      } else {
        newTotals.push({
          key : `${tax.id}`,
          taxId : tax.id,
          total : calculateTax(linePrice, tax),
        });
      }
    });

    const subtotal = newTotals.find(t => t.key === 'subtotal');
    if (subtotal) {
      subtotal.total.amount += linePrice.amount;
      subtotal.total.calculatedValue += linePrice.calculatedValue;
    } else {
      newTotals.push({
        key : 'subtotal',
        total : { ...linePrice },
      });
    }

    const total = newTotals.find(t => t.key === 'total');
    const totalAmount = newTotals.reduce(
      (sum, t) => sum + ((t.key === 'subtotal' || t.taxId || t.adjustmentId)
        ? t.total.amount
        : 0),
      0,
    );
    if (total) {
      total.total.amount = totalAmount;
      total.total.calculatedValue = totalAmount * total.total.increment;
    } else {
      newTotals.push({
        key : 'total',
        total : {
          amount : totalAmount,
          currencyCode : 'CAD',
          increment : 0.01,
          calculatedValue : totalAmount * 0.01,
        },
      });
    }

    return newTotals;
  }, [calculateTax]);

  const addLineTotals = useCallback((
    totals : Subtotal[],
    lineItem : LineItem | Selection,
    quantity : number = 1,
  ) : Subtotal[] => {
    const itemPrice = calculateLinePrice(lineItem);
    const applicableTaxes = taxes ? listRecords(taxes).filter(
      t => t.productIds.includes(lineItem.productId)
    ) : [];

    if (!itemPrice) return totals;
    const totalPrice = (quantity !== 1)
      ? multiplyCurrency(itemPrice, quantity)
      : itemPrice;
    return updateTotals(totals, totalPrice, applicableTaxes);
  }, [updateTotals, taxes, calculateLinePrice]);

  const buildTotals = useCallback((order : ProjectedOrder) => {
    const newOrder = { ...order };
    const record = newOrder.order;

    const service = order.serviceChannel ? determineService(newOrder) : null;

    const unaccountedItems = Object.values(newOrder.lineItems).filter(
      lineItem => !record || !Object.values(record.fulfilments).some(
        (f) => ((f.lineItemId === lineItem.id )
          && (f.requestedProductId === lineItem.productId))
      )
    );
    const unaccountedSelections = Object.values(newOrder.selections).filter(
      select => !record || !Object.values(record.fulfilments).some(
        (f) => ((select.fulfilmentIds?.includes(f.id ?? NaN))
      )
    ));
    const unaccountAdjustments = service
      ? Object.values(getServiceAdjustments(service)).filter(
        adjustment => !record
          || !Object.values(record.appliedAdjustments).some(
            (a) => a.adjustmentId === adjustment.id
          )
      ) : [];

    if (!unaccountedItems.length && !unaccountedSelections.length) {
      return newOrder;
    }

    let workingTotals = [ ...newOrder.totals ];
    for (const newItem of unaccountedItems) {
      workingTotals = addLineTotals(workingTotals, newItem);
    }
    for (const newSelection of unaccountedSelections) {
      const item = Object.values(newOrder.lineItems).find(
        lineItem => lineItem.id === newSelection.lineItemId
      );
      workingTotals = addLineTotals(
        workingTotals,
        newSelection,
        item?.quantity,
      );
    }

    const subtotal = workingTotals.find(t => t.key === 'subtotal')
      ?? {
        key : 'subtotal',
        total : {
          amount : 0,
          currencyCode : 'CAD',
          increment : 0.01,
          calculatedValue : 0,
        },
      };
    const total = workingTotals.find(t => t.key === 'total')
      ?? {
        key : 'total',
        total : {
          amount : 0,
          currencyCode : 'CAD',
          increment : 0.01,
          calculatedValue : 0,
        },
      };

    for (const adjustment of unaccountAdjustments) {
      const amount = adjustment.currency?.amount
        ? adjustment.currency
        : multiplyCurrency(subtotal.total, adjustment.factor ?? 1);

      const existingAdjustment = workingTotals.find(
        t => t.adjustmentId === adjustment.id
      );
      if (existingAdjustment) {
        existingAdjustment.total = addCurrency(
          existingAdjustment.total,
          amount,
        );
      } else {
        workingTotals.push({
          key : `adjustment-${adjustment.id}`,
          adjustmentId : adjustment.id,
          total : amount,
        });
      }

      total.total = addCurrency(total.total, amount);

      const tax = listRecords(taxes)
        .filter(t => t.id && adjustment.taxIds.includes(t.id));

      for (const t of tax) {
        const taxAmount = calculateTax(amount, t);
        const existingTax = workingTotals.find(
          tax => tax.taxId === t.id
        );
        if (existingTax) {
          existingTax.total = addCurrency(existingTax.total, taxAmount);
        } else {
          workingTotals.push({
            key : `tax-${t.id}`,
            taxId : t.id,
            total : taxAmount,
          });
        }

        total.total = addCurrency(total.total, taxAmount);
      }
    }

    newOrder.totals = workingTotals;
    return newOrder;
  }, [
    addLineTotals,
    calculateTax,
    determineService,
    getServiceAdjustments,
    taxes,
  ]);

  const projectOrders = useCallback(async (
    projectFrom? : Date,
    projectTo? : Date,
  ) => {
    // TODO: project to only include only complete, unfinished orders
    const from = projectFrom ?? (orders
      ? new Date(orders.reduce(
        (min, order) => order.time ? Math.min(min, order.time?.getTime()) : min,
        new Date().getTime(),
      )) : new Date());
    const to = projectTo
      ?? new Date((new Date()).getTime() + 1000 * 60 * 60 * 24 * 7 * 8);

    const projection = [] as ProjectedOrder[];
    if (lineItems === null) return [];

    for (const lineItem of listRecords(lineItems)) {
      if (!lineItem.timeSlotId) {
        const order = projection?.find(
          order => matchLineItemOrder(lineItem, order)
        ) ?? null;
        if (order) {
          order.lineItems[lineItem.id ?? 0] = lineItem;
          continue;
        }

        const newOrder = await createProjectedOrderFromLineItem(lineItem);
        if (newOrder) projection.push(newOrder);
        continue;
      }

      const [
        minIteration,
        maxIteration,
        applicableSubscriptions,
      ] = findIterationsInRange(
        lineItem,
        subscriptions ? listRecords(subscriptions) : [],
        from,
        to,
      );
      if (minIteration === undefined || maxIteration === undefined) continue;

      for (let i = minIteration; i <= maxIteration; i++) {
        const subscription = evaluateSubscriptions(applicableSubscriptions, i);
        if (!subscription) {
          if (i !== lineItem.timeSlotIteration) continue;

          const order = projection?.find(
            order => matchLineItemOrder(lineItem, order)
          ) ?? null;
          if (order) {
            order.lineItems[lineItem.id ?? 0] = lineItem;
            continue;
          }

          const newOrder = await createProjectedOrderFromLineItem(lineItem);
          if (newOrder) projection.push(newOrder);
          continue;
        }

        if ((i - subscription.startIteration) % subscription.period !== 0) {
          continue;
        }
        if (!subscription.quantity) continue;

        const order = projection.find(
          order => (order.customer?.id === lineItem.customerId)
            && matchSubscriptionOrder(subscription, order, i)
        );
        if (order) {
          order.lineItems[lineItem.id ?? 0] = lineItem;
          order.subscriptions[subscription.id ?? 0] = subscription;
          continue;
        }

        const newOrder = await createProjectedOrderFromSubscription(
          lineItem,
          subscription,
          i,
        );
        if (newOrder) projection.push(newOrder);
      }
    }

    projection.push(...(await buildOrphanedOrders(projection)).map(
      order => ({
        ...order,
        selections : {},
        subscriptions : {},
      })
    ));

    const customisedProjections = projection.map(customiseProjectedOrder);
    const totaledOrders = customisedProjections.map(buildTotals);
    totaledOrders.forEach(validateOrder);

    return totaledOrders;
  }, [
    subscriptions,
    matchLineItemOrder,
    matchSubscriptionOrder,
    customiseProjectedOrder,
    createProjectedOrderFromLineItem,
    createProjectedOrderFromSubscription,
    evaluateSubscriptions,
    findIterationsInRange,
    buildTotals,
    lineItems,
    orders,
    buildOrphanedOrders,
    validateOrder,
  ]);

  useEffect(() => {
    if (!orderAddresses) return;

    const addressIds = listRecords(subscriptions).reduce((ids, sub) => {
      if (sub.addressId && !ids.includes(sub.addressId))
        ids.push(sub.addressId);
      return ids;
    }, [] as number[]);
    const missingAddresses = addressIds.filter(id => !orderAddresses?.[id]);
    if (!missingAddresses.length) return;

    retrieveAddressesBulk(missingAddresses).then((add) => {
      if (add) dispatchOrderAddresses(add);
    });
  }, [
    subscriptions,
    orderAddresses,
    dispatchOrderAddresses,
    retrieveAddressesBulk,
  ]);

  useEffect(
    () => { projectOrders().then(setProjectedOrders); },
    [projectOrders],
  );

  const context = {
    subscriptions,
    susbcriptionOptions : options,
    projectedOrders,
    loaded : subscriptionLoaded && optionsLoaded,
    load,
    createSubscription : newSubscription,
    reloadSubscriptions : reloadSubsctiptions,
    refreshSubscriptions,
    refreshSubscription,
    retrieveSubscriptions : getSubscriptions,
    retrieveSubscription : getSubscription,
    bulkCreateSubscriptions : bulkNewSubscriptions,
    retrieveSubscriptionOptions : getOptions,
    addServiceChannelToSubscriptionOption : addOptionServiceChannel,
    removeServiceChannelFromSubscriptionOption : removeOptionServiceChannel,
    evaluateSubscriptions,
    projectOrders,
    bulkCreateFulfilments : bulkNewFulfilments,
  };

  return (
    <SubscriptionsContext.Provider value={context}>
      { children }
    </SubscriptionsContext.Provider>
  );
}

export default SubscriptionsContext;
