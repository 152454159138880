import React, { useEffect, useState } from 'react';

import { Fulfilment, LineItem, Selection, DraftCustomOrder } from '#types';

import useProducts from '#hooks/useProducts';
import useOptions from '#hooks/useOptions';

import { settings } from '#materials';
import Banner from '#materials/Banner';
import { CellElement, TableCell } from '#materials/TableCell';

import SelectionTable, {
  SELECTION_TABLE_KEYS,
  TableKey,
  defaultSelectionTableKeys,
} from '#components/selections/SelectionTable';
import { OrderFormMode } from '#components/orders/OrderForm';

import locale, { localize } from '#utils/locale';

const localeFormKeys = locale.keys.forms.selections;

export { SELECTION_TABLE_KEYS, defaultSelectionTableKeys };
export type { TableKey };

interface LineItemSelectionsRowProps {
  order? : DraftCustomOrder;
  lineItem : LineItem;
  selections : Selection[];
  setSelection? : (selection : Selection) => void;
  mode? : OrderFormMode;
  colSpan? : number;
  disabled? : boolean;
  generateActions? : (selection : Selection | null) => CellElement;
  updateFulfilment? : (fulfilment : Fulfilment) => void | Promise<void>;
  deleteFulfilment? : (fulfilment : Fulfilment) => void | Promise<void>;
  extraRow? : React.ReactNode;
  tableKeys? : TableKey[];
}

function LineItemSelectionsRow({
  order,
  lineItem,
  selections,
  tableKeys = defaultSelectionTableKeys,
  mode,
  colSpan = tableKeys.length,
  disabled,
  extraRow,
  setSelection,
  generateActions,
  updateFulfilment,
  deleteFulfilment,
} : LineItemSelectionsRowProps) {
  const { products } = useProducts();
  const { validateCustomItem } = useOptions();

  const [error, setError] = useState<string>('');

  useEffect(() => {
    const product = products?.[lineItem.productId];
    if (!product) {
      setError('Product not found');
      return;
    }

    const validation = validateCustomItem(lineItem, order);
    if (!validation) return;
    const { valid, errors } = validation;

    const es = Object.values(errors)
    for (const e of es) {
      if (e.key === 'invalidAssembly') {
        setError(localize(localeFormKeys.errors.invalidAssembly) +
          (e?.assembly?.name ? `: ${e.assembly?.name}` : ''));
        return;
      }
    }
    for (const e of es) {
      if (e.key === 'invalidProduct') {
        setError(localize(localeFormKeys.errors.invalidProduct) +
          `: ${e.product?.name}`);
        return;
      }
    }
    for (const e of es) {
      if (e.key === 'tooMany') {
        setError(localize(localeFormKeys.errors.tooMany) +
          (e?.assembly?.name ? `: ${e.assembly?.name}` : ''));
        return;
      }
    }
    for (const e of es) {
      if (e.key === 'tooFew') {
        setError(localize(localeFormKeys.errors.tooFew) +
          (e?.assembly?.name ? `: ${e.assembly?.name}` : ''));
        return;
      }
    }

    if (!valid) {
      setError(localize(localeFormKeys.errors.unknown));
      return;
    }
    setError('');
  }, [
    lineItem,
    selections,
    products,
    order,
    validateCustomItem,
  ]);

  return (
    <TableCell
      colSpan={colSpan}
      height={settings.dimensions.auto}
      padding={settings.spacings.dense}
    >
      { error && (
        <Banner
          colour={settings.colours.alert.alert}
          height={settings.dimensions.small}
          width={settings.dimensions.full}
        >
          { error }
        </Banner>
      ) }
      <SelectionTable
        lineItem={lineItem}
        selections={selections}
        order={order}
        mode={mode}
        disabled={disabled}
        setSelection={setSelection}
        updateFulfilment={updateFulfilment}
        deleteFulfilment={deleteFulfilment}
        generateActions={generateActions}
        extraRow={extraRow}
        tableKeys={tableKeys}
      />
    </TableCell>
  );
}

export default LineItemSelectionsRow;
