import React, { useCallback } from 'react';

import useNotifications from '#hooks/useNotifications';
import useData from '#hooks/useData';

import { settings } from '#materials'
import Icon from '#materials/Icon';
import Button from '#materials/Button';

import Section from '#components/dashboard/Section';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.settings.appSettings;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.app;

function AppSettings() {
  const { createNotification } = useNotifications();
  const { clearCache : clearStorage } = useData();
  const { clearCache : clearDB } = useData({ useDB : true });

  const handleClearCache = useCallback(async () => {
    clearStorage();
    clearDB();
    createNotification({
      key : 'clear-cache-sucess',
      message : localize(localeNotificationKeys.clearCache.success),
      colour : settings.colours.alert.primary,
      icon : <Icon icon={settings.svgIcons.settings} />
    });

    await new Promise(resolve => setTimeout(resolve, 2000));
    window.location.reload();
  }, [clearStorage, clearDB, createNotification]);

  return (
    <Section
      title={localize(localeContentKeys.title)}
      text={localize(localeContentKeys.body)}
    >
      <Button
        colour={settings.colours.button.alert}
        onClick={handleClearCache}
      >
        { localize(localeButtonKeys.clearCache) }
      </Button>
    </Section>
  );
}

export default AppSettings;
