import React, { useMemo, useState } from 'react';

import { Address } from '#types';

import useAddress from '#hooks/useAddresses';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import { TableActionCell, Action } from '#materials/TableCell';

import Section from '#components/dashboard/Section';
import CreateAddress from '#components/addresses/CreateAddress';
import AddressTable from '#components/addresses/AddressTable';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeKeys = locale.keys.content.addressIndex;

interface AddressIndexProps {
  showHead? : boolean;
}

function AddressIndex({ showHead = true } : AddressIndexProps) {
  const { addresses, deleteAddress } = useAddress();

  const [creatingNew, setCreatingNew] = useState(false);

  const addressList = useMemo(() => listRecords(addresses), [addresses]);

  const tableLength = addresses ? Object.keys(addresses).length : 0;

  function handleCreateNew() {
    setCreatingNew(true);
  }

  function handleCancelNew() {
    setCreatingNew(false);
  }

  function handleSave() {
    setCreatingNew(false);
  }

  function handleDelete(address : Address) {
    return () => {
      async function del() {
        await deleteAddress(address);
      }

      del()
    }
  }

  function generateActions(address : Address) {
    return (
      <TableActionCell>
        <Action
          label={`Delete Address ${address.id}`}
          onClick={handleDelete(address)}
          colour={settings.colours.button.alert}
        >
          <Icon icon={settings.svgIcons.delete}/>
        </Action>
      </TableActionCell>
    )
  }

  return (
    <>
      { showHead &&
        <Section
          title={localize(localeKeys.title)}
          text={localize(localeKeys.body)}
        >
          <Button
            onClick={handleCreateNew}
          >
            Create New
          </Button>
        </Section>
      }
      { creatingNew &&
        <CreateAddress onSave={handleSave} onCancel={handleCancelNew}/>
      }
      <Section
        title={localize(localeKeys.index.title)}
      >
        { !!tableLength &&
          <AddressTable
            addresses={addressList}
            count={tableLength}
            generateActions={generateActions}
          />
        }
      </Section>
    </>
  );
}

export default AddressIndex;
