import React, { useCallback, useEffect } from 'react';

import useNavigation, { useSearchParams } from '#hooks/useNavigation';
import useCustomers from '#hooks/useCustomers';
import useServices from '#hooks/useServices';
import useRoutes from '#hooks/useRoutes';
import useScheduling from '#hooks/useScheduling';
import useProducts from '#hooks/useProducts';
import useCategories from '#hooks/useCategories';
import useOrders from '#hooks/useOrders';

import { parseDateTime } from '#utils/date';

import Report from '#components/orders/OrdersReport';

function OrdersReport() {
  const [urlParams] = useSearchParams();
  const { adjustSearchParams } = useNavigation();

  const { load : loadCustomer } = useCustomers();
  const { load : loadServices } = useServices();
  const { load : loadRoutes } = useRoutes();
  const { load : loadTimeSlots } = useScheduling();
  const { load : loadProducts } = useProducts();
  const { load : loadCategories, loaded : categoriesLoaded } = useCategories();
  const { load, loaded } = useOrders();

  const setCustomer = useCallback((customerId : number | null) => {
    adjustSearchParams({ customer : customerId });
  }, [adjustSearchParams]);
  const setServiceChannel = useCallback((serviceChannelId : number | null) => {
    adjustSearchParams({ serviceChannel : serviceChannelId });
  }, [adjustSearchParams]);
  const setLocation = useCallback((locationId : number | null) => {
    adjustSearchParams({ location : locationId });
  }, [adjustSearchParams]);
  const setRoute = useCallback((routeId : number | false | null) => {
    adjustSearchParams({ route : routeId });
  }, [adjustSearchParams]);
  const setTimeSlot = useCallback((timeSlotId : number | null) => {
    adjustSearchParams({ timeSlot : timeSlotId });
  }, [adjustSearchParams]);
  const setFromDate = useCallback((date : Date | null) => {
    adjustSearchParams({ from : date?.toISOString() });
  }, [adjustSearchParams]);
  const setToDate = useCallback((date : Date | null) => {
    adjustSearchParams({ to : date?.toISOString() });
  }, [adjustSearchParams]);
  const setProduct = useCallback((productId : number | null) => {
    adjustSearchParams({ product : productId });
  }, [adjustSearchParams]);
  const setCategory = useCallback((categoryId : number | null) => {
    adjustSearchParams({ category : categoryId });
  }, [adjustSearchParams]);

  useEffect(() => {
    loadCustomer();
    loadServices();
    loadRoutes();
    loadTimeSlots();
    loadProducts();
    loadCategories();
    load();
  }, [
    loadCustomer,
    loadServices,
    loadRoutes,
    loadTimeSlots,
    loadProducts,
    loadCategories,
    load,
  ]);

  if (!loaded || !categoriesLoaded) return null;

  const customerId = parseInt(urlParams.get('customer') ?? '');
  const serviceChannelId = parseInt(urlParams.get('serviceChannel') ?? '');
  const locationId = parseInt(urlParams.get('location') ?? '')
  const routeId = urlParams.get('route') === 'false'
    ? false
    : parseInt(urlParams.get('route') ?? '');
  const timeSlotId = parseInt(urlParams.get('timeSlot') ?? '');

  const fromDate = parseDateTime(urlParams.get('from') ?? '');
  const toDate = parseDateTime(urlParams.get('to') ?? '');

  const productId = parseInt(urlParams.get('product') ?? '');
  const categoryId = parseInt(urlParams.get('category') ?? '');

  const props = {
    ...(!isNaN(customerId) && { customerId }),
    ...(!isNaN(serviceChannelId) && { serviceChannelId }),
    ...(!isNaN(locationId) && { locationId }),
    ...(routeId === false
      ? { routeId : false as false }
      : !isNaN(routeId) && { routeId }),
    ...(!isNaN(timeSlotId) && { timeSlotId }),
    ...(!isNaN(fromDate.getTime()) && { fromDate }),
    ...(!isNaN(toDate.getTime()) && { toDate }),
    ...(!isNaN(productId) && { productId }),
    ...(!isNaN(categoryId) && { categoryId }),
  }

  return (
    <Report
      setCustomer={setCustomer}
      setServiceChannel={setServiceChannel}
      setLocation={setLocation}
      setRoute={setRoute}
      setTimeSlot={setTimeSlot}
      setFromDate={setFromDate}
      setToDate={setToDate}
      setProduct={setProduct}
      setCategory={setCategory}
      {...props}
    />
  );
}

export default OrdersReport;
