import React, { useCallback, useMemo, useState } from 'react';

import {
  Customer,
  ServiceChannel,
  Location,
  Route,
  TimeSlot,
  Fulfilment,
  Order,
  DraftOrder,
  ProjectedOrder,
  isFulfilmentStatus,
} from '#types';

import useMediaQuery from '#hooks/useMediaQuery';
import useNotifications from '#hooks/useNotifications';
import useCustomers from '#hooks/useCustomers';
import useServices from '#hooks/useServices';
import useRoutes from '#hooks/useRoutes';
import useScheduling from '#hooks/useTimeSlots';
import useOrders from '#hooks/useOrders';
import useOptions from '#hooks/useOptions';
import useSubscriptions from '#hooks/useSubscriptions';
import useNotes from '#hooks/useNotes';

import { settings } from '#materials';
import Segment from '#materials/Segment';
import Icon from '#materials/Icon';
import Banner from '#materials/Banner';
import Button from '#materials/Button';
import ToggleButton, { ToggleButtonGroup } from '#materials/ToggleButton';
import { Action, TableActionCell } from '#materials/TableCell';

import Section from '#components/dashboard/Section';
import OrdersSearchControl from '#components/orders/OrdersSearch';
import OrderTable, {
  TABLE_KEYS,
  defatultTableKeys,
} from '#components/orders/OrderTable';
import CreateOrder from '#components/orders/CreateOrder';
import Holds from '#components/orders/Holds';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.orders.orderIndex;
const localeNotificationKeys = locale.keys.notifications;
const localeTableKeys = locale.keys.tables.orders;
const localeButtonKeys = locale.keys.buttons;

const tabletTableKeys = [
  TABLE_KEYS.info,
  TABLE_KEYS.id,
  TABLE_KEYS.date,
  TABLE_KEYS.time,
  TABLE_KEYS.status,
  TABLE_KEYS.actions,
]

function isOrderGenerated(order : ProjectedOrder) : boolean {
  return !!(order?.order
    && Object.keys(order.order?.fulfilments).length >= (
      Object.keys(order.lineItems).length + Object.keys(order.selections).length
    )
    && Object.values(order.lineItems).every((li) => (
      order.order
        && Object.values(order.order.fulfilments).some(f => (
          f.lineItemId === li.id && f.requestedProductId === li.productId
        ))
    ))
    && Object.values(order.selections).every((s) => (
      order.order
        && Object.values(order.order.fulfilments).some(f => (
          f.id && s.fulfilmentIds.includes(f.id)
        ))
    )));
}

function ungeneratedOrders(orders : ProjectedOrder[]) : ProjectedOrder[] {
  return orders.filter((order) => !isOrderGenerated(order));
}

function payableOrders(orders : DraftOrder[]) : Order[] {
  return orders.filter((order) => order?.order?.id && !order?.paid)
    .map((order) => order.order) as Order[];
}

interface OrderIndexProps {
  query? : {
    customerId? : number | null;
    serviceChannelId? : number | null;
    locationId? : number | null;
    routeId? : number | false | null;
    timeSlotId? : number | null;
    fromDate? : Date | null;
    toDate? : Date | null;
    showIncomplete? : boolean;
    showGuest? : boolean;
    setCustomer? : (customerId : number | null) => void;
    setServiceChannel? : (serviceChannelId : number | null) => void;
    setLocation? : (locationId : number | null) => void;
    setRoute? : (routeId : number | false | null) => void;
    setTimeSlot? : (timeSlotId : number | null) => void;
    setFromDate? : (date : Date | null) => void;
    setToDate? : (date : Date | null) => void;
    setShowIncomplete? : (showIncomplete : boolean) => void;
    setShowGuest? : (showGuest : boolean) => void;
  };
};

function OrderIndex({ query } : OrderIndexProps) {
  const { isDesktop } = useMediaQuery();
  const { createNotification } = useNotifications();
  const { customers } = useCustomers();
  const { serviceChannels, locations } = useServices();
  const { routes } = useRoutes();
  const { timeSlots } = useScheduling();
  const {
    reloadLineItems,
    refreshLineItems,
    bulkUpdateFulfilments,
    reloadOrders,
    refreshOrders,
    refreshHolds,
    generateOrderUrl,
    payOrders,
  } = useOrders();
  const { reloadSelections, refreshSelections } = useOptions();
  const {
    reloadSubscriptions,
    refreshSubscriptions,
    generateOrder,
  } = useSubscriptions();
  const { refreshNotes } = useNotes();

  const [refreshing, setRefreshing] = useState(false);
  const [creating, setCreating] = useState(false);
  const [orders, setOrders] = useState<ProjectedOrder[]>([]);

  const [confirmProcess, setConfirmProcess] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState('');

  const customer = useMemo(() => (
    query?.customerId ? (customers?.[query.customerId] ?? null) : null
  ), [query, customers]);
  const serviceChannel = useMemo(() => (
    query?.serviceChannelId
      ? (serviceChannels?.[query.serviceChannelId] ?? null)
      : null
  ), [query, serviceChannels]);
  const location = useMemo(() => (
    query?.locationId ? (locations?.[query.locationId] ?? null) : null
  ), [query, locations]);
  const route = useMemo(() => (
    query?.routeId === false
      ? false
      : (query?.routeId ? (routes?.[query.routeId] ?? null) : null)
  ), [query, routes]);
  const timeSlot = useMemo(() => (
    query?.timeSlotId ? (timeSlots?.[query.timeSlotId] ?? null) : null
  ), [query, timeSlots]);

  const refresh = useCallback(async () => {
    setRefreshing(true);
    await Promise.all([
      refreshLineItems(),
      refreshOrders(),
      refreshSelections(),
      refreshSubscriptions(),
      refreshHolds(),
      refreshNotes(),
    ]);
    setRefreshing(false);
  }, [
    refreshLineItems,
    refreshOrders,
    refreshSelections,
    refreshHolds,
    refreshSubscriptions,
    refreshNotes,
  ]);

  const reload = useCallback(async () => {
    setRefreshing(true);
    await Promise.all([
      reloadLineItems(),
      reloadOrders(),
      reloadSelections(),
      reloadSubscriptions(),
      refreshHolds(),
      refreshNotes(),
    ]);
    setRefreshing(false);
  }, [
    reloadLineItems,
    reloadOrders,
    reloadSelections,
    reloadSubscriptions,
    refreshHolds,
    refreshNotes,
  ]);

  const setCustomer = useCallback((c : Customer | null) => {
    query?.setCustomer?.(c?.id ?? null);
  }, [query]);
  const setServiceChannel = useCallback((sc : ServiceChannel | null) => {
    query?.setServiceChannel?.(sc?.id ?? null);
  }, [query]);
  const setLocation = useCallback((l : Location | null) => {
    query?.setLocation?.(l?.id ?? null);
  }, [query]);
  const setRoute = useCallback((r : Route | false | null) => {
    query?.setRoute?.((r === false) ? false : (r?.id ?? null));
  }, [query]);
  const setTimeSlot = useCallback((ts : TimeSlot | null) => {
    query?.setTimeSlot?.(ts?.id ?? null);
  }, [query]);

  const handleCreate = useCallback(() => {
    setCreating(true);
  }, [setCreating]);

  const handleCancel = useCallback(() => {
    setCreating(false);
  }, [setCreating]);

  const handleInitProcess = useCallback(() => {
    setConfirmProcess(true);
  }, [setConfirmProcess]);

  const handleCancelProcess = useCallback(() => {
    setConfirmProcess(false);
  }, [setConfirmProcess]);

  const handleConfirmProcess = useCallback(async () => {
    const ungeneratedOrders = orders.filter(
      (o) => o.status === 'pending' || !isOrderGenerated(o)
    );
    const newOrders = (await Promise.all(
      ungeneratedOrders.map((order) => generateOrder(order, {
        defer : false,
        failFast : true,
        resolveSelections : true,
      })),
    )).map((order) => listRecords(order)).flat();

    const payable = payableOrders(orders).filter((order) =>
      !newOrders.some((newOrder) => newOrder.id === order.id)
    );

    const realOrders = [
      ...newOrders,
      ...payable,
    ];
    if (!realOrders.length) return;

    setProcessing(true);
    const paidOrders = await payOrders(realOrders);
    if (paidOrders) {
      createNotification({
        key : 'process-order-success',
        message : localize(localeNotificationKeys.orders.process.success),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'process-order-error',
        message : localize(localeNotificationKeys.orders.process.error),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.alert,
      });
    }
    setProcessing(false);
    setConfirmProcess(false);
  }, [orders, generateOrder, payOrders, createNotification]);

  const generateActions = useCallback((order : DraftOrder) => {
    return (
      <TableActionCell>
        <Action
          label={localize(localeTableKeys.actions.view)}
          href={
            generateOrderUrl(
              order,
              { relative : true, searchPrefix : 'search' },
            )
          }
        >
          <Icon icon={settings.svgIcons.receipt} />
        </Action>
      </TableActionCell>
    )
  }, [generateOrderUrl]);

  const handleStatusInit = useCallback((status : string) => {
    setConfirmStatus(status);
  }, [setConfirmStatus]);

  const handleStatusCancel = useCallback(() => {
    setConfirmStatus('');
  }, [setConfirmStatus]);

  const handleStatusConfirm = useCallback(async () => {
    if (!isFulfilmentStatus(confirmStatus)) return;

    const ungeneratedOrders = orders.filter((o) =>
      o.status === 'pending' || !isOrderGenerated(o)
    );
    const newOrders = (await Promise.all(
      ungeneratedOrders.map((order) => generateOrder(order, {
        defer : false,
        failFast : true,
        resolveSelections : true,
      })),
    )).map((order) => listRecords(order)).flat();

    const unique = [
      ...newOrders,
      ...orders
        .filter((o) => o.order && !newOrders.some((n) => n.id === o.order?.id))
        .map((o) => o.order) as Order[],
    ];

    const fulfilments = unique.reduce((acc, order) => {
      if (order.fulfilments) {
        return acc.concat(Object.values(order.fulfilments));
      }
      return acc;
    }, [] as Fulfilment[]);
    if (!fulfilments.length) return;

    const updates = await bulkUpdateFulfilments(
      fulfilments.map((fulfilment) => ({
        ...fulfilment,
        status : confirmStatus,
      })
    ));
    if (updates) {
      createNotification({
        key : 'update-fulfilments-success',
        message : localize(
          localeNotificationKeys.fulfilments.bulkStatusUpdate.success,
        ),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'update-fulfilments-error',
        message : localize(
          localeNotificationKeys.fulfilments.bulkStatusUpdate.error,
        ),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.alert,
      });
    }
    setConfirmStatus('');
  }, [
    orders,
    confirmStatus,
    generateOrder,
    bulkUpdateFulfilments,
    createNotification,
  ]);

  const busy = refreshing || processing || confirmProcess || !!confirmStatus;
  const status = (orders.length === 0 && 'pending')
    || (orders.every((order) => order.status === 'fulfilled') && 'fulfilled')
    || (orders.every((order) => order.status === 'ready') && 'ready')
    || (orders.every((order) => order.status === 'inProgress') && 'inProgress')
    || ''

  return (
    <>
      <Section
        title={localize(localeContentKeys.title)}
        text={localize(localeContentKeys.body)}
      >
        <Button onClick={refresh} onLongPress={reload} disabled={refreshing}>
          { localize(localeButtonKeys.refresh) }
        </Button>
        <Button onClick={handleCreate} disabled={creating}>
          { localize(localeButtonKeys.new) }
        </Button>
      </Section>
      { creating && (
        <CreateOrder
          onSave={handleCancel}
          onCancel={handleCancel}
        />
      ) }
      <Holds />
      <Section
        title={localize(localeContentKeys.index.title)}
        text={localize(localeContentKeys.index.body)}
      >
        <OrdersSearchControl
          setOrders={setOrders}
          showIncompleFilters
          customer={customer}
          serviceChannel={serviceChannel}
          location={location}
          route={route}
          timeSlot={timeSlot}
          fromDate={query?.fromDate}
          toDate={query?.toDate}
          showIncomplete={query?.showIncomplete}
          showGuest={query?.showGuest}
          setCustomer={setCustomer}
          setServiceChannel={setServiceChannel}
          setLocation={setLocation}
          setRoute={setRoute}
          setTimeSlot={setTimeSlot}
          setFromDate={query?.setFromDate}
          setToDate={query?.setToDate}
          setShowIncomplete={query?.setShowIncomplete}
          setShowGuest={query?.setShowGuest}
        />
        <Segment
          title={orders.length
            ? `${orders.length} ${(orders.length === 1)
                ? localize(localeContentKeys.index.orderFound)
                : localize(localeContentKeys.index.ordersFound)}`
            : localize(localeContentKeys.index.noOrdersFound)
          }
        />
        { confirmProcess && (
          <Banner
            icon={(<Icon icon={settings.svgIcons.info} />)}
            actions={(
              <>
                <Button onClick={handleConfirmProcess}>
                  { localize(localeButtonKeys.process) }
                </Button>
                <Button onClick={handleCancelProcess}>
                  { localize(localeButtonKeys.cancel) }
                </Button>
              </>
            )}
            colour={settings.colours.alert.secondary}
          >
            {`${localize(localeContentKeys.index.confirmProcess)}
              (${payableOrders(orders).length})`}
          </Banner>
        ) }
        { confirmStatus && (
          <Banner
            icon={(<Icon icon={settings.svgIcons.info} />)}
            actions={(
              <>
                <Button onClick={handleStatusConfirm}>
                  { localize(localeButtonKeys.update) }
                </Button>
                <Button onClick={handleStatusCancel}>
                  { localize(localeButtonKeys.cancel) }
                </Button>
              </>
            )}
            colour={settings.colours.alert.secondary}
          >
            {`${localize(localeContentKeys.index.confirmStatus)}
              (${orders.length})`}
          </Banner>
        ) }
        <Button
          onClick={handleInitProcess}
          disabled={
            busy || !orders.length || (!payableOrders(orders).length
              && !ungeneratedOrders(orders).length)
          }
        >
          { orders.every(order => order.paid)
            ? localize(localeContentKeys.paid)
            : localize(localeContentKeys.processPayments)
          }
        </Button>
        <ToggleButtonGroup disabled={!orders.length}>
          <ToggleButton
            value="inProgress"
            selected={status === 'inProgress'}
            onSelect={handleStatusInit}
            first
          >
            { localize(localeContentKeys.markInProgress) }
          </ToggleButton>
          <ToggleButton
            value="ready"
            selected={status === 'ready'}
            onSelect={handleStatusInit}
          >
            { localize(localeContentKeys.markReady) }
          </ToggleButton>
          <ToggleButton
            value="fulfilled"
            selected={status === 'fulfilled'}
            onSelect={handleStatusInit}
            last
          >
            { localize(localeContentKeys.markFulfiled) }
          </ToggleButton>
        </ToggleButtonGroup>
        <OrderTable
          orders={orders}
          generateActions={generateActions}
          tableKeys={isDesktop ? defatultTableKeys : tabletTableKeys}
          pageCount={20}
        />
      </Section>
    </>
  );
}

export default OrderIndex;
