import React, { useCallback, useEffect, useState } from 'react';

import { ExternalCustomer, Integration } from '#types';

import useIntegrations from '#hooks/useIntegrations';
import useCustomers from '#hooks/useCustomers';

import { settings } from '#materials';
import Text from '#materials/Text';
import Form from '#materials/Form';
import Button from '#materials/Button';
import TextInput from '#materials/TextInput';
import Select from '#materials/Select';
import { CellElement } from '#materials/TableCell';

import ExternalCustomerTable from '#components/customers/ExternalCustomerTable';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeButtonsKeys = locale.keys.buttons;
const localeTableKeys = locale.keys.tables.externalCustomers;

const CUSTOMER_CHANNEL = 'customers';

interface ExternalCustomerSearchProps {
  integration? : Integration | null;
  setIntegration? : (integration : Integration | null) => void;
  filter? :
    (externalCustomers : ExternalCustomer[]) => ExternalCustomer[];
  generateActions? : (customer : ExternalCustomer) => CellElement;
  onCancel? : () => void;
}

function noChange(integration : Integration | null) {}

function ExternalCustomerSearch({
  integration = null,
  setIntegration = noChange,
  filter = (externalCustomers : ExternalCustomer[]) => externalCustomers,
  generateActions,
  onCancel,
} : ExternalCustomerSearchProps) {
  const { retrieveIntegrations, retrieveChannels } = useIntegrations();
  const { retrieveExternalCustomers } = useCustomers();

  const [
    externalCustomers,
    setExternalCustomers,
  ] = useState<ExternalCustomer[] | null>(null);
  const [integrations, setIntegrations] = useState<Integration[] | null>(null);
  const [query, setQuery] = useState<string>('');
  const [querying, setQuerying] = useState<boolean>(false);
  const [queried, setQueried] = useState<string>('');
  const [
    filteredCustomers,
    setFilteredCustomers,
  ] = useState<ExternalCustomer[]>([]);

  const retrieve = useCallback(async () => {
    const [newIntegrations, newChannels] = await Promise.all([
      retrieveIntegrations(),
      retrieveChannels(),
    ]);

    if (!newChannels) return;
    const channel = listRecords(newChannels).find(
      (channel) => channel.name === CUSTOMER_CHANNEL
    ) ?? null
    if (!channel) return;

    if (newIntegrations) setIntegrations(
      listRecords(newIntegrations).filter((integration) => {
        return channel?.id && integration.channelIds.includes(channel?.id);
      })
    );
  }, [
    retrieveIntegrations,
    retrieveChannels,
  ]);

  const searchExternalCustomers = useCallback(async () => {
    setQuerying(true);
    const ext = await retrieveExternalCustomers(query);
    setQuerying(false);
    if (ext) setExternalCustomers(
      Object.values(ext).map((e) => Object.values(e)).flat()
    );
    setQueried(query);
  }, [query, retrieveExternalCustomers, setExternalCustomers]);

  const handleCancel = useCallback(() => { onCancel?.(); }, [onCancel]);

  useEffect(() => { retrieve(); }, [retrieve]);

  useEffect(() => {
    if (!externalCustomers) return;

    if (integration === null) {
      setFilteredCustomers([]);
      return;
    }

    let filtered = externalCustomers.filter(
      (ext) => ext.integrationId === integration.id,
    );
    setFilteredCustomers(filter(filtered));
  }, [filter, externalCustomers, integration, query, setFilteredCustomers]);

  return (
    <>
      <Button
        onClick={searchExternalCustomers}
        disabled={!integration || !query || querying || (query === queried)}
      >
        { localize(localeButtonsKeys.search) }
      </Button>
      <Button
        onClick={handleCancel}
        colour={settings.colours.alert.alert}
      >
        { localize(localeButtonsKeys.close) }
      </Button>
      <Form onSubmit={searchExternalCustomers}>
        <Select
          label="Integration"
          selected={integration}
          options={integrations ? integrations : []}
          onChange={setIntegration}
          labelGenerator={(integration) => integration?.name ?? ''}
          width={settings.dimensions.third}
        />
        <TextInput
          id="integration-customer-search-query"
          label="Search"
          value={query}
          onChange={setQuery}
          width={settings.dimensions.twoThirds}
        />
      </Form>
      { integration && <>
        <ExternalCustomerTable
          customerIntegrations={filteredCustomers}
          generateActions={generateActions}
        />
        { !filteredCustomers.length &&
          <Text>
            { localize(localeTableKeys.notFound) }
          </Text>
        }
      </> }
    </>
  );
}

export default ExternalCustomerSearch;
