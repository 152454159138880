import React, { useCallback } from 'react';

import { Assembly } from '#types';

import useForm from '#hooks/useForm';

import { settings } from '#materials';
import TextInput from '#materials/TextInput';
import Form from '#materials/Form';
import Switch from '#materials/Switch';

import locale, { localize } from '#utils/locale';

const localKeys = locale.keys.forms.assemblies;

interface AssemblyFormProps {
  assembly : Assembly;
  onSubmit? : () => void;
}

function AssemblyForm({ assembly : fallback, onSubmit } : AssemblyFormProps) {
  const { state, dispatch, errors, editing } = useForm<Assembly>();

  const setName = useCallback((name : string) => {
    dispatch({ name });
  }, [dispatch]);
  const setCumulative = useCallback((cumulative : boolean) => {
    dispatch({ cumulative });
  }, [dispatch]);
  const setComplimentary = useCallback((complimentary : boolean) => {
    dispatch({
      complimentary,
      ...(!complimentary && { automatic: false }),
    });
  }, [dispatch]);
  const setAutomatic = useCallback((automatic : boolean) => {
    dispatch({ automatic });
  }, [dispatch]);

  const idPrefix = (fallback && fallback.id)
    ? `assembly-${fallback?.id}`
    : 'assembly-new';

  return (
    <Form onSubmit={onSubmit}>
      <TextInput
        id={`${idPrefix}-name`}
        label={localize(localKeys.labels.name)}
        value={state ? state.name : fallback?.name}
        onChange={setName}
        disabled={!editing}
        width={settings.dimensions.half}
        errors={errors.name}
      />
      <Switch
        checked={state ? state.cumulative : fallback?.cumulative}
        label={localize(localKeys.labels.cumulative)}
        onChange={setCumulative}
        disabled={!editing}
        width={settings.dimensions.quarter}
      />
      <Switch
        checked={state ? state.complimentary : fallback?.complimentary}
        label={localize(localKeys.labels.complimentary)}
        onChange={setComplimentary}
        disabled={!editing}
        width={settings.dimensions.quarter}
      />
      { (state?.complimentary || fallback?.complimentary) && (
        <Switch
          checked={state ? state.automatic : fallback?.automatic}
          label={localize(localKeys.labels.automatic)}
          onChange={setAutomatic}
          disabled={!editing || !state?.complimentary}
          width={settings.dimensions.full}
        />
      ) }
    </Form>
  );
}

export default AssemblyForm;
