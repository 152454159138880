import React, {
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';

import { LineItem, Selection, Fulfilment, ProjectedOrder } from '#types';

import useProducts from '#hooks/useProducts';
import useTags from '#hooks/useTags';
import useOrders from '#hooks/useOrders';
import useOptions from '#hooks/useOptions';
import useSubscriptions from '#hooks/useSubscriptions';
import useNotes from '#hooks/useNotes';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Table from '#materials/Table';
import {
  CellElement,
  TableCell,
  TableActionCell,
  Action,
} from '#materials/TableCell';

import LineItemRow, {
  TABLE_KEYS,
  TableKey,
  defaultTableKeys,
} from '#components/lineItems/LineItemRow';
import LineItemSelectionsRow, {
  TableKey as SelectionTableKey,
  defaultSelectionTableKeys,
} from '#components/lineItems/LineItemSelectionsRow';
import { OrderFormMode } from '#components/orders/OrderForm';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

export type { TableKey };
export { TABLE_KEYS, defaultTableKeys };

const localeTableKeys = locale.keys.tables.lineItems;

function reduceSelections(
  selections : Selection[],
  action : {
    type : string,
    selections? : Selection | Selection[],
    lineItemIds? : number[],
  },
) {
  if (
    action.selections !== undefined
      && !Array.isArray(action.selections)
  ) return reduceSelections(
    selections,
    { ...action, selections : [action.selections] }
  );

  if (action.lineItemIds) {
    if (action.type !== 'keep') return selections;
    return selections.filter(
      (s) => action.lineItemIds
        && action.lineItemIds.includes(s.lineItemId),
    );
  }
  if (!action.selections) return selections;

  let minId = Math.min(...selections.map((s) => s.id ?? 0), 0);
  const payload = action.selections.map((p) => ({
    ...p,
    id : p.id ?? --minId,
  }));
  switch(action.type) {
    case 'add':
      return payload.reduce((acc, p) => {
        if (selections.some((s) => s.id === p.id)) return acc.map(
          (s) => s.id === p.id ? p : s
        );
        return [...acc, p];
      }, selections);
    case 'remove':
      return selections.filter(
        (s) => !payload.some((p) => p.id === s.id),
      );
    case 'set':
      return payload;
    default: return selections;
  }
}

interface LineItemTableProps {
  order? : ProjectedOrder;
  lineItems : LineItem[];
  selections : Selection[];
  mode? : OrderFormMode;
  editing? : number[];
  showNotes? : boolean;
  setEditing? : (editing : number[]) => void;
  disabled? : boolean;
  pageCount? : number;
  onSave? : (
    lineItem : LineItem,
    selections? : Selection[],
    options? : { period? : number },
  ) => void | boolean | Promise<void | null | boolean>;
  onDeleteFulfilment? : (fulfilment : Fulfilment) => () => void | Promise<void>;
  generateActions? : (lineItem : LineItem) => CellElement;
  beforeRow? : (lineItem : LineItem) => React.ReactNode | React.ReactNode[];
  replaceRow? : (lineItem : LineItem) => React.ReactNode | React.ReactNode[];
  extraRow? : React.ReactNode | React.ReactNode[];
  onUpdateFulfilment? : (fulfilment : Fulfilment) => void | Promise<void>;
  disableProduct? : boolean;
  tableKeys? : TableKey[];
  selectionTableKeys? : SelectionTableKey[];
}

function LineItemTable({
  order,
  lineItems,
  selections : initialSelections,
  mode,
  editing,
  setEditing,
  showNotes = false,
  disabled = false,
  pageCount,
  onSave,
  onUpdateFulfilment,
  onDeleteFulfilment,
  generateActions,
  beforeRow,
  replaceRow,
  extraRow,
  disableProduct = false,
  tableKeys = defaultTableKeys,
  selectionTableKeys = defaultSelectionTableKeys,
} : LineItemTableProps) {
  const { products } = useProducts();
  const { isProductAvailable } = useTags();
  const { calculateFulfilmentPrice } = useOrders();
  const {
    getProductAssemblies,
    getAssemblyProducts,
    isProductCustomisable,
    validateSelections,
    listOrphanedFulfilments,
  } = useOptions();
  const { getLineItemSubscription } = useSubscriptions();
  const { getLineItemNotes } = useNotes();

  const [selections, dispatchSelections] = useReducer(
    reduceSelections,
    initialSelections,
  );

  const [head, setHead] = useState<React.ReactNode>(<></>);
  const [rows, setRows] = useState<React.ReactNode[]>([]);

  const dispatchSelection = useCallback((selection : Selection) => {
    dispatchSelections({ type : 'add', selections : selection });
  }, [dispatchSelections]);

  const setEditingLineItem = useCallback((
    lineItem : LineItem,
    edit : boolean,
  ) => {
    if (!lineItem.id) return;
    if (!setEditing) return;
    setEditing(edit
      ? [...(editing ?? []), lineItem.id]
      : editing?.filter((id) => id !== lineItem.id) ?? [],
    );
  }, [editing, setEditing]);

  const handleSave = useCallback(async (
    lineItem : LineItem,
    options? : { period? : number },
  ) => {
    const selected = selections.filter((s) => ((s.lineItemId === lineItem.id)));
    if ((await onSave?.(lineItem, selected, options)) === false) return;
    setEditingLineItem(lineItem, false);
  }, [onSave, selections, setEditingLineItem]);

  const handleCancel = useCallback((lineItem : LineItem) => () => {
    setEditingLineItem(lineItem, false);
  }, [setEditingLineItem]);

  const handleAddSelection = useCallback(
    (lineItem : LineItem) => () => {
      if (!order || !lineItem.id) return;

      const product = listRecords(products)
        .find(p => p.id === lineItem.productId);
      if (!product) return;

      const assemblies = getProductAssemblies(product);
      for (const assembly of assemblies) {
        if (!assembly?.id) return;

        const assemblyProduct = getAssemblyProducts(
          assembly,
          order.time ?? new Date(),
        )[0];
        if (!assemblyProduct?.id) continue;

        dispatchSelection({
          lineItemId : lineItem.id,
          assemblyId : assembly.id,
          productId : assemblyProduct.id,
          quantity : 1,
          fulfilmentIds : [],
        });
        break;
      }
    }, [
      order,
      products,
      dispatchSelection,
      getProductAssemblies,
      getAssemblyProducts,
    ],
  );

  const generateHead = useCallback(() => {
    setHead(
      <>
        { tableKeys.map((key) => {
          switch(key) {
            case TABLE_KEYS.id:
              return (<TableCell key={key}>
                { localize(localeTableKeys.headings.id) }
              </TableCell>);
            case TABLE_KEYS.productSku:
              return (<TableCell key={key}>
                { localize(localeTableKeys.headings.productSku) }
              </TableCell>);
            case TABLE_KEYS.product:
              return (<TableCell key={key}>
                { localize(localeTableKeys.headings.product) }
              </TableCell>);
            case TABLE_KEYS.period:
              return (<TableCell key={key}>
                { localize(localeTableKeys.headings.period) }
              </TableCell>);
            case TABLE_KEYS.requestedQty:
              return (
                <TableCell key={key} alignment={settings.alignments.right}>
                  { localize(localeTableKeys.headings.quantity) }
                </TableCell>
              );
            case TABLE_KEYS.price:
              return (<TableCell
                key={key}
                alignment={settings.alignments.right}
              >
                { localize(localeTableKeys.headings.price) }
              </TableCell>);
            default: return (<TableCell key={key} />);
          }
        }) }
      </>
    );
  }, [tableKeys]);

  const generateExtraSelectionRow = useCallback((lineItem : LineItem) => {
    if (
      mode === 'fulfilment'
        || !editing?.includes(lineItem.id ?? NaN)
    ) return null;

    return (selectionTableKeys.map((key) => {
      switch(key) {
        case TABLE_KEYS.actions:
          return (
            <TableActionCell key={key} width={settings.dimensions.xsmall}>
              <Action
                label={localize(localeTableKeys.actions.add)}
                onClick={handleAddSelection(lineItem)}
                disabled={disabled}
              >
                <Icon icon={settings.svgIcons.add} />
              </Action>
            </TableActionCell>
          );
        default: return (<TableCell key={key} />);
      }
    }));
  }, [disabled, editing, selectionTableKeys, mode, handleAddSelection]);

  const generateSelectionActions = useCallback((
    selection : Selection | null,
  ) => (
    <TableActionCell width={settings.dimensions.xsmall}>
      { (selection && editing?.includes(selection.lineItemId ?? NaN)) && (
        <Action
          label={localize(localeTableKeys.actions.remove)}
          onClick={() => dispatchSelections({
            type : 'remove',
            selections : selection,
          })}
          disabled={disabled || !editing?.includes(selection.lineItemId ?? NaN)}
          colour={settings.colours.button.alert}
        >
          <Icon icon={settings.svgIcons.remove} />
        </Action>
      )}
    </TableActionCell>
  ), [disabled, editing, dispatchSelections]);

  const generateFulfilmentActions = useCallback(
    (fulfilment : Fulfilment) => (lineItem : LineItem) => (
      onDeleteFulfilment
        ? (
          <TableActionCell width={settings.dimensions.xsmall}>
            <Action
              label={localize(localeTableKeys.actions.remove)}
              onClick={onDeleteFulfilment(fulfilment)}
              colour={settings.colours.button.alert}
              disabled={disabled}
            >
              <Icon icon={settings.svgIcons.clear} />
            </Action>
          </TableActionCell>
        ) : (
          <TableCell width={settings.dimensions.xsmall}/>
        )
    ),
    [disabled, onDeleteFulfilment],
  );

  const generateRows = useCallback(() => {
    const rows = lineItems.reduce((lines, lineItem) => {
      const product = listRecords(products).find(
        (product) => product.id === lineItem.productId,
      );
      const time = order?.time;
      const subscription = order
        ? (getLineItemSubscription(lineItem, order) ?? undefined)
        : undefined;

      if (beforeRow) {
        const before = beforeRow(lineItem);
        if (before) {
          if (Array.isArray(before)) {
            lines.push(...before.map((row, i) => (
              <React.Fragment key={`before-${lineItem.id}-${i}`}>
                { row }
              </React.Fragment>
            )));
          }
          else {
            lines.push(
              <React.Fragment key={`before-${lineItem.id}`}>
                { before }
              </React.Fragment>
            );
          }
        }
      }

      const productValid = (product && order?.serviceChannel)
        && isProductAvailable(product, order.serviceChannel);
      const replacement = replaceRow?.(lineItem);
      const editingLine = !!lineItem.id && editing?.includes(lineItem.id);
      lines.push(replacement ?? (
        <LineItemRow
          key={`${lineItem.id}`}
          order={order}
          lineItem={lineItem}
          subscription={subscription}
          mode={mode}
          editing={editingLine}
          disableProduct={disableProduct}
          disabled={disabled}
          onSave={handleSave}
          onCancel={handleCancel(lineItem)}
          onUpdateFulfilment={onUpdateFulfilment}
          generateActions={generateActions}
          tableKeys={tableKeys}
          icon={productValid ? undefined : (
            <Icon
              icon={settings.svgIcons.info}
              colour={settings.colours.button.alert}
            />
          )}
        />
      ));

      const lineItemSelections = selections.filter(
        (selection) => selection.lineItemId === lineItem.id
      );

      const fulfilmentSelections = initialSelections.filter((selection) => {
        return selection.lineItemId === lineItem.id
      })

      const renderedSelections = mode === 'fulfilment'
        ? fulfilmentSelections
        : lineItemSelections;

      const customisable = !!product
        && !!time
        && isProductCustomisable(product, time);
      const valid = customisable
        ? validateSelections(
          product,
          lineItemSelections,
          time,
        ).valid : false;
      const renderCustomisation = customisable
        && (!valid
          || !!renderedSelections.length
          || (mode === 'edit' && editingLine));

      if (renderCustomisation) {
        lines.push(
          <LineItemSelectionsRow
            key={`${lineItem.id}-selections`}
            lineItem={lineItem}
            selections={renderedSelections}
            order={order}
            setSelection={dispatchSelection}
            updateFulfilment={onUpdateFulfilment}
            deleteFulfilment={(f) => onDeleteFulfilment?.(f)()}
            extraRow={generateExtraSelectionRow(lineItem)}
            mode={mode}
            disabled={disabled || !editing?.includes(lineItem.id ?? NaN)}
            tableKeys={selectionTableKeys}
            colSpan={tableKeys.length}
            generateActions={
              mode === 'fulfilment'
                ? undefined
                : generateSelectionActions
            }
          />
        );
      }

      if (showNotes) {
        const notes = getLineItemNotes(lineItem);
        if (notes.length) {
          lines.push(
            <TableCell
              key={`${lineItem.id}-notes`}
              colSpan={tableKeys.length}
            >
              { notes.reduce(
                (acc, note) => acc + note.content + ' ',
                'Note: ',
              ) }
            </TableCell>
          );
        }
      }

      return lines;
    }, [] as React.ReactNode[]);

    const orphanedFullfillments = (order
      ? listOrphanedFulfilments(order).fulfilments
      : []).filter((f) => !lineItems.some((i) => i.id === f.lineItemId));
    if (mode === 'fulfilment' && orphanedFullfillments.length) {
      rows.push(...orphanedFullfillments.map((f) => (
        <LineItemRow
          key={`${f.lineItemId}-${f.id}`}
          order={order}
          lineItem={{
            id : f.lineItemId ?? f.id,
            productId : f.fulfilledProductId ?? f.requestedProductId,
            quantity : f.fulfilledQty ?? f.requestedQty,
            addressId : order?.address?.id ?? null,
            customerId : order?.customer?.id ?? null,
            serviceChannelId : order?.serviceChannel?.id ?? null,
            locationId : order?.location?.id ?? null,
            timeSlotId : order?.timeSlot?.id ?? null,
            timeSlotIteration : order?.timeSlotIteration ?? 0,
            timeSlotDivision : order?.timeSlotDivision ?? 0,
            price : calculateFulfilmentPrice(f, { quantity : 1 }),
            guestCode : '',
          }}
          fulfilment={f}
          mode={mode}
          editing
          disableProduct={disableProduct}
          disabled={disabled}
          fade={disabled}
          onUpdateFulfilment={onUpdateFulfilment}
          generateActions={generateFulfilmentActions(f)}
          icon={(<Icon
            icon={settings.svgIcons.info}
            colour={settings.colours.button.alert}
          />)}
          tableKeys={tableKeys}
        />
      )));
    }

    if (extraRow) {
      if (Array.isArray(extraRow)) {
        rows.push(...extraRow.map((row, i) => (
          <React.Fragment key={i}>{ row }</React.Fragment>
        )));
      }
      else {
        rows.push(<React.Fragment key={'extra'}>{ extraRow }</React.Fragment>);
      }
    }
    setRows(rows);
  }, [
    order,
    lineItems,
    initialSelections,
    mode,
    tableKeys,
    selectionTableKeys,
    beforeRow,
    replaceRow,
    onUpdateFulfilment,
    onDeleteFulfilment,
    extraRow,
    showNotes,
    disabled,
    editing,
    disableProduct,
    selections,
    handleSave,
    handleCancel,
    dispatchSelection,
    generateActions,
    generateSelectionActions,
    generateExtraSelectionRow,
    generateFulfilmentActions,
    products,
    isProductAvailable,
    isProductCustomisable,
    calculateFulfilmentPrice,
    validateSelections,
    listOrphanedFulfilments,
    getLineItemSubscription,
    getLineItemNotes,
  ]);

  useEffect(() => {
    dispatchSelections({
      type : 'keep',
      lineItemIds : editing,
    });
    dispatchSelections({
      type : 'add',
      selections : initialSelections.filter((s) => (
        !editing?.includes(s.lineItemId)
      )),
    });
  }, [editing, initialSelections]);

  useEffect(() => { generateHead() }, [generateHead]);
  useEffect(() => { generateRows() }, [generateRows]);

  return (
    <Table
      head={head}
      rows={rows}
      pageCount={pageCount}
    />
  );
}

export default LineItemTable;
