import { useMediaQuery as useMuiMediaQuery } from '@mui/material';

import useConfig from '#hooks/useConfig';

function useMediaQuery() {
  const { config } = useConfig();
  const isPrint = useMuiMediaQuery('print');

  const isTablet = useMuiMediaQuery(
    `(min-width: ${config.theme.breakpoints.tablet})`
  );
  const isDesktop = useMuiMediaQuery(
    `(min-width: ${config.theme.breakpoints.desktop})`
  );

  return {
    isPrint,
    isTablet,
    isDesktop
  };
}

export default useMediaQuery;
