import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { createTheme, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { setConfig as setClerkConfig } from '#utils/clerk';

import defaults from '../defaults.json';

const ConfigContext = createContext({
  config : defaults,
  theme : createTheme({
    ...defaults,
    layout : {
      barHeight : 60,
      drawerWidth : 240,
    },
  }),
  ready : false,
  default : true,
});

interface ConfigProviderProps {
  children : React.ReactNode;
}

export function ConfigProvider({ children } : ConfigProviderProps) {
  const outer = useContext(ConfigContext);

  const [config, setConfig] = useState(() => {
    try {
      const cache = localStorage.getItem('config');
      return cache ? JSON.parse(cache) : defaults;
    } catch (e) {
      console.error('Failed to load cached config.json');
      return defaults;
    }
  });

  const [fetched, setFetched] = useState(false);
  const [ready, setReady] = useState(false);

  const theme = useMemo(() => createTheme({
    ...config.theme,
    layout : {
      barHeight : 60,
      drawerWidth : 240,
    },
  }), [config]);
  const context = useMemo(() => ({
    config,
    theme,
    ready,
    default : false,
  }), [config, ready, theme]);

  useEffect(() => {
    if (!outer.default || fetched) return;
    fetch('/config.json', { cache: 'reload' })
      .then((response) => response.json())
      .then((config) => {
        config = { ...defaults, ...config };
        try { localStorage.setItem('config', JSON.stringify(config)); }
        catch (e) { console.error('Failed to cache config.json'); }
        setConfig(config);
        setClerkConfig({ domain : config.settings?.clerk?.domain });
        setFetched(true);
      })
      .catch(() => console.error('Failed to fetch config.json'));
  }, [fetched, outer]);

  useEffect(() => {
    if (!outer.default) return;
    try {
      if (localStorage.getItem('config')) {
        setReady(true);
        return;
      }
    } catch (e) { }
    setReady(fetched);
  }, [fetched, outer]);

  useEffect(() => {
    if (!outer.default) return;
    setClerkConfig({
      domain : config.settings.clerk.domain
        || process.env.REACT_APP_DOMAIN
        || '',
    });
  }, [config, outer]);

  return (
    <ConfigContext.Provider value={context}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          { children }
        </LocalizationProvider>
      </ThemeProvider>
    </ConfigContext.Provider>
  );
}

export default ConfigContext;
