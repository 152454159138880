import React, { useCallback, useRef } from 'react';

import MuiButton from '@mui/material/Button';
import { useMediaQuery } from '@mui/material';

import { useTheme } from '#materials';

import {
  Component,
  ButtonVariant,
  ButtonColour,
  Size,
  convert,
  settings,
} from '#materials/types';
import { IconElement } from '#materials/Icon';

const PRESS_DELAY = 1000; // ms

interface ButtonProps {
  onClick? : () => void;
  onLongPress? : () => void;
  href? : string;
  disabled? : boolean;
  component? : Component;
  variant? : ButtonVariant;
  size? : Size;
  colour? : ButtonColour;
  icon? : IconElement;
  children? : React.ReactNode;
}

function Button({
  onClick,
  onLongPress,
  href,
  disabled = false,
  component,
  variant = settings.variants.button.full,
  size = settings.sizes.medium,
  colour = settings.colours.button.primary,
  icon,
  children,
} : ButtonProps) {
  const theme = useTheme();
  const isPrint = useMediaQuery('print');

  const pressTimer = useRef<NodeJS.Timeout | null>(null);
  const longPressed = useRef<boolean>(false);

  const onPress = useCallback(() => {
    if (!onLongPress) return;
    pressTimer.current = setTimeout(() => {
      onLongPress();
      longPressed.current = true;
    }, PRESS_DELAY);
  }, [onLongPress]);

  const onRelease = useCallback(() => {
    if (!onLongPress) return;
    if (pressTimer.current) clearTimeout(pressTimer.current);
    if (!longPressed.current && onClick) onClick();
    longPressed.current = false;
  }, [onClick, onLongPress]);

  const hasMargin = variant !== settings.variants.button.inline &&
    variant !== settings.variants.button.table

  const my = (hasMargin && variant !== settings.variants.button.text)
    ? 1
    : 0;

  const buttonSize = variant !== settings.variants.button.text
    ? convert.size(size)
    : 'small';
  const buttonVairiant = variant !== settings.variants.button.text
    ? 'outlined'
    : 'text';

  if (isPrint) return null;

  return (
    <MuiButton
      component={component ? component : settings.components.button}
      variant={buttonVairiant}
      size={buttonSize}
      href={href}
      onClick={!onLongPress ? onClick : undefined}
      onMouseDown={onPress}
      onMouseUp={onRelease}
      disabled={disabled}
      color={colour}
      startIcon={icon}
      sx={{
        mx : 1,
        my,
        ...(buttonVairiant === 'outlined' && {
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: theme.palette[colour].main,
          color: '#130303',
          backgroundColor: theme.palette[colour].light,
          '& .MuiTouchRipple-root': {
            color : theme.palette[colour].main,
          },
        }),
        ...(buttonVairiant === 'text' && {
          color: theme.palette[colour].dark
        }),
      }}
    >
      { children }
    </MuiButton>
  )
}

export type ButtonElement = React.ReactElement<ButtonProps>;

export function isButtonElement(
  element : React.ReactNode
) : element is ButtonElement {
  return React.isValidElement<ButtonProps>(element) &&
    element.type === Button;
}

export default Button;
