import React, { useCallback } from 'react';

import { Area } from '#types';

import { FormProvider } from '#context/FormContext';

import { useFormContext } from '#hooks/useForm';
import useAddresses from '#hooks/useAddresses';

import { settings } from '#materials';
import Button from '#materials/Button';

import AreaForm from '#components/areas/AreaForm';

import locale, { localize } from '#utils/locale';

const localeButtonKeys = locale.keys.buttons;

interface CreateAreaProps {
  onSave? : (area : Area) => void;
  onCancel? : () => void;
}

function CreateAreaControl({ onSave, onCancel } : CreateAreaProps) {
  const { createArea } = useAddresses();
  const { state : area, reset, valid } = useFormContext<Area>();

  const handleSave = useCallback(async () => {
    if (!area || !valid) return;

    const firstVertex = area.vertices[0];
    const lastVertex = area.vertices[area.vertices.length - 1];
    if (
      (firstVertex[0] !== lastVertex[0])
        || (firstVertex[1] !== lastVertex[1])
    ) {
      area.vertices.push([...firstVertex]);
    }

    const newArea = await createArea(area);
    if (newArea && onSave) onSave(newArea);
  }, [area, valid, createArea, onSave]);

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);

  return (
    <>
      { !!area && <AreaForm area={area} /> }
      <Button
        onClick={handleSave}
        disabled={!valid}
      >
        { localize(localeButtonKeys.save) }
      </Button>
      <Button
        onClick={reset}
        colour={settings.colours.button.alert}
      >
        { localize(localeButtonKeys.clear) }
      </Button>
      <Button
        onClick={handleCancel}
        colour={settings.colours.button.alert}
      >
        { localize(localeButtonKeys.cancel) }
      </Button>
    </>
  );
}

function CreateArea({ onSave, onCancel } : CreateAreaProps) {
  return (
    <FormProvider
      init={{
        name : '',
        vertices : [],
      }}
      editing={true}
    >
      <CreateAreaControl
        onSave={onSave}
        onCancel={onCancel}
      />
    </FormProvider>
  );
}

export default CreateArea;
