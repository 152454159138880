import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Customer } from '#types';

import useNotifications from '#hooks/useNotifications';
import useNotes from '#hooks/useNotes';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import TextInput from '#materials/TextInput';

import Section from '#components/dashboard/Section';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.customerNotes;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications.notes;

interface CustomerNotesProps {
  customer : Customer;
}

function CustomerNotes({ customer } : CustomerNotesProps) {
  const { createNotification } = useNotifications();
  const {
    createNote,
    updateNote,
    deleteNote,
    getCustomerNotes,
    generateDefaultNote,
  } = useNotes();

  const notes = useMemo(
    () => getCustomerNotes(customer),
    [customer, getCustomerNotes],
  );

  const [editing, setEditing] = useState(false);
  const [note, setNote] = useState(notes.map((n) => n.content).join('\n\n'));

  const handleEdit = useCallback(() => { setEditing(true); }, [setEditing]);
  const handleCancel = useCallback(() => {
    setNote(notes.map((n) => n.content).join('\n\n'));
    setEditing(false);
  }, [notes]);

  const handleSave = useCallback(async () => {
    const customerId = customer.id;
    if (!customerId) return;
    let success = true;

    if (notes.length === 0) {
      success = !!(await createNote({
        ...generateDefaultNote({ customerId }),
        content : note,
      }));
    } else {
      if (notes.length > 1) {
        const responses = await Promise.all(
          notes.map((n) => deleteNote(n)),
        );
        success = responses.every((r) => r);
      }
      if (success) {
        success = !!(await updateNote({
          ...notes[0],
          content : note,
        }));
      }
    }

    if (success) {
      createNotification({
        key : 'save-note-success',
        message : localize(localeNotificationKeys.update.success),
        icon : (<Icon icon={settings.svgIcons.person} />),
        colour : settings.colours.alert.primary,
      });
      setEditing(false);
    } else {
      createNotification({
        key : 'save-note-error',
        message : localize(localeNotificationKeys.update.error),
        icon : (<Icon icon={settings.svgIcons.person} />),
        colour : settings.colours.alert.alert,
      });
    }
  }, [
    customer,
    notes,
    note,
    createNote,
    updateNote,
    deleteNote,
    generateDefaultNote,
    createNotification,
  ]);

  useEffect(() => {
    setNote(notes.map((n) => n.content).join('\n\n'));
  }, [notes]);

  return (
    <Section
      title={localize(localeContentKeys.title)}
      text={localize(localeContentKeys.body)}
    >
      <TextInput
        id='customer-note'
        value={note}
        onChange={setNote}
        minRows={2}
        disabled={!editing}
      />
      { editing
        ? (
          <>
            <Button
              onClick={handleSave}
              colour={settings.colours.button.primary}
            >
              {localize(localeButtonKeys.save)}
            </Button>
            <Button
              onClick={handleCancel}
              colour={settings.colours.button.alert}
            >
              {localize(localeButtonKeys.cancel)}
            </Button>
          </>
        ) : (
          <Button
            onClick={handleEdit}
            colour={settings.colours.button.primary}
          >
            {localize(localeButtonKeys.edit)}
          </Button>
        )
      }
    </Section>
  );
}

export default CustomerNotes;
